<div class="interview-details-container" *ngIf="interview && application">
  <!-- Top Action Buttons -->
  <div class="action-buttons">
    <button class="action-button btn card" (click)="openDialog(reassignDialog)">Reassign</button>
    <button class="action-button btn card" (click)="openDialog(cancelDialog)" [disabled]="isInterviewCanceled()">
      Cancel Interview
    </button>

    <button class="action-button btn card" (click)="openDialog(scheduleDialog)" [disabled]="isInterviewScheduled()">
      Schedule
    </button>
  </div>

  <!-- Interview Details -->
  <div class="interview-details card">
    <h3>Interview Details</h3>
    <div class="details-grid">
      <div class="details-group">
        <p><strong>Candidate Name:</strong> {{ application.candidate.displayName }}</p>
        <p><strong>Email:</strong> {{ application.candidate.mail }}</p>
        <p><strong>Phone:</strong> {{ application.candidate.mobilePhone }}</p>
        <p><strong>Location:</strong> {{ application.candidate.country }}</p>
        <p><strong>CV: </strong><a [href]="application.cvUrl" target="_blank" rel="noopener noreferrer">Open</a></p>
        <p *ngIf="application.additionalDoc1Url">
          <strong>Additional Doc 1: </strong
          ><a [href]="application.additionalDoc1Url" target="_blank" rel="noopener noreferrer">Open</a>
        </p>
        <p *ngIf="application.additionalDoc2Url">
          <strong>Additional Doc 2: </strong
          ><a [href]="application.additionalDoc2Url" target="_blank" rel="noopener noreferrer">Open</a>
        </p>
        <p *ngIf="application.additionalDoc3Url">
          <strong>Additional Doc 3: </strong
          ><a [href]="application.additionalDoc3Url" target="_blank" rel="noopener noreferrer">Open</a>
        </p>
        <p><strong>Candidate Availability:</strong> {{ application.availability }}</p>
        <p *ngIf="interview.reassignmentComments">
          <strong>Reassignment Comments:</strong> {{ interview.reassignmentComments }}
        </p>
        <p>
          <strong>Expected Salary:</strong> {{ application.expectedSalary }} {{ application.position.salaryCurrency }}
        </p>
        <p><strong>HR Notes:</strong> <span [innerHTML]="application.hrNotes"></span></p>
      </div>
      <div class="details-group">
        <p><strong>Interview Status:</strong> {{ interview.status }}</p>
        <p><strong>Application Status:</strong> {{ getApplicationStatusValue(application.status) }}</p>
        <p><strong>Round </strong> {{ interview.round.roundNumber }} out of {{ getTotalNumberOfRounds() }}</p>
        <p><strong>Interview Date:</strong> {{ interview.startDate | date : 'short' }}</p>
        <p><strong>Application Submitted:</strong> {{ application.created | date : 'short' }}</p>
        <p *ngIf="interview.ended"><strong>End Date:</strong> {{ interview.ended | date : 'short' }}</p>
        <p><strong>Interviewers:</strong></p>
        <ejs-listview [dataSource]="interview.round.interviewers" [fields]="{ text: 'email' }">
          <ng-template #template let-data>
            <div class="list-item">{{ data.displayName }}</div>
          </ng-template>
        </ejs-listview>
        <p><strong>Approvals:</strong></p>
        <ejs-listview [dataSource]="interview.approvedBy" [fields]="{ text: 'name' }">
          <ng-template #template let-data>
            <div class="list-item">{{ data }}</div>
          </ng-template>
        </ejs-listview>
        <p><strong>Rejections:</strong></p>
        <ejs-listview [dataSource]="interview.rejectedBy" [fields]="{ text: 'name' }">
          <ng-template #template let-data>
            <div class="list-item">{{ data }}</div>
          </ng-template>
        </ejs-listview>
      </div>

      <div *ngIf="previousInterviews.length > 0">
        <p><strong>Interviewer notes from previous rounds:</strong></p>
        <div class="details-group dynamic-container">
          <div *ngFor="let prevInterview of previousInterviews">
            <div *ngFor="let postInterview of prevInterview.postInterviews" class="note-card">
              <strong>{{ postInterview.interviewerDisplayName }}: </strong>
              <span>{{ postInterview.interviewNotes }}</span>
            </div>
          </div>
        </div>
        <p><strong>Post interview forms from previous rounds:</strong></p>
        <div class="details-group dynamic-container">
          <div *ngFor="let prevInterview of previousInterviews">
            <div *ngFor="let postInterview of prevInterview.postInterviews">
              <p *ngIf="postInterview.postInterviewDocumentUrl">
                <strong
                  >{{ postInterview.interviewerDisplayName }} for round {{ prevInterview.round.roundNumber }}
                </strong>
                <a [href]="postInterview.postInterviewDocumentUrl" target="_blank">Download</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="button-group">
      <button
        class="action-button btn btn-success"
        (click)="openDialog(approveDialog)"
        [disabled]="!canClickApproveButton()"
      >
        {{ isLastRound() ? 'Approve & Move to Potential Offer' : 'Approve & Move to Next Interview' }}
      </button>
      <button
        [disabled]="!canClickRejectButton()"
        class="action-button btn btn-danger"
        (click)="openDialog(rejectDialog)"
      >
        Reject
      </button>
      <button
        [disabled]="!canClickConsideringButton()"
        class="action-button btn btn-primary"
        (click)="openDialog(consideringDialog)"
      >
        Considering
      </button>
    </div>
  </div>

  <!-- Position Details -->
  <div class="position-details card">
    <h3>Position Details</h3>
    <ul>
      <li><strong>Job Title:</strong> {{ application.position.jobTitle }}</li>
      <div>
        <li>
          <strong>Salary Range:</strong>
          <span>
            {{ application.position.salaryMin }} to {{ application.position.salaryMax
            }}{{ application.position.salaryCurrency }}</span
          >
        </li>
      </div>
      <li><strong>Location:</strong> {{ getLocationValue(application.position.location) }}</li>
      <li><strong>Team:</strong> {{ getTeamValue(application.position.team) }}</li>
    </ul>
  </div>

  <!-- Answers to Questions -->
  <div class="answers card" *ngIf="application.answers.length > 0">
    <h3>Answers</h3>
    <div *ngFor="let answer of application.answers" class="question-answer">
      <p>
        <strong [innerHTML]="answer.question.question"></strong>
      </p>
      <p [innerHTML]="answer.answer"></p>
    </div>
  </div>

  <!-- Dialogs -->
  <ejs-dialog #scheduleDialog header="Schedule Interview" width="800" [isModal]="true" [visible]="false">
    <ng-template #content>
      <div class="dialog-content">
        <div class="form-group">
          <ejs-datetimepicker
            id="newInterviewDateTime"
            [(ngModel)]="newInterviewDateTime"
            placeholder="Select a date and time"
            dateTimeFormat="d M yyyy hh:mm tt"
            [min]="minDateTime"
          ></ejs-datetimepicker>
        </div>
        <div class="buttons">
          <button class="action-button btn btn-success" (click)="confirmAction(scheduleDialog, 'schedule')">
            Confirm
          </button>
          <button class="action-button btn btn-danger" (click)="closeDialog(scheduleDialog)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>

  <ejs-dialog #consideringDialog header="Considering Offer" width="800" [isModal]="true" [visible]="false">
    <ng-template #content>
      <div class="dialog-content">
        <div class="form-group">
          <ejs-textbox
            #notesTextbox
            [multiline]="true"
            [(ngModel)]="notes"
            placeholder="Enter notes"
            width="100%"
          ></ejs-textbox>
        </div>
        <div class="form-group">
          <h6>Post Interview Form</h6>
          <ejs-uploader
            #defaultupload
            id="defaultfileupload"
            dropArea="ejs-uploader"
            (select)="onFileRemove($event)"
            (selected)="setPostInterviewDoc($event)"
            allowedExtensions=".pdf,.docx"
          ></ejs-uploader>
        </div>
        <div class="buttons">
          <button class="action-button btn btn-success" (click)="confirmAction(consideringDialog, 'considering')">
            Confirm
          </button>
          <button class="action-button btn btn-danger" (click)="closeDialog(consideringDialog)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>

  <ejs-dialog #cancelDialog header="Cancel Interview" width="800" [isModal]="true" [visible]="false">
    <ng-template #content>
      <div class="dialog-content">
        <div class="buttons">
          <button class="action-button btn btn-success" (click)="confirmAction(cancelDialog, 'cancel')">Confirm</button>
          <button class="action-button btn btn-danger" (click)="closeDialog(cancelDialog)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>

  <ejs-dialog #reassignDialog header="Reassign Interview" width="800" [isModal]="true" [visible]="false">
    <ng-template #content>
      <div class="dialog-content">
        <div class="form-group">
          <div class="lists-container">
            <div class="list-container">
              <div class="dual-list-groupa">
                <h5>Staff</h5>
                <ejs-listview
                  class="staff-list"
                  [dataSource]="staff"
                  [fields]="fields"
                  (select)="toggleSelection($event)"
                >
                  <ng-template #template let-data>
                    <div class="staff-item">
                      <span class="staff-name">{{ data.name.display }}</span>
                    </div>
                  </ng-template>
                </ejs-listview>
              </div>
            </div>
            <div class="list-container">
              <h5>Selected Interviewers</h5>
              <ejs-listview
                #selectedInterviewers
                class="selected-interviewers-list"
                [dataSource]="reassignForm.get('interviewers').value"
                [fields]="fields"
              >
                <ng-template #template let-data>
                  <div class="selected-interviewer-item">
                    <label class="custom-checkbox">
                      <input
                        type="checkbox"
                        (click)="toggleCheckbox($event, data.email)"
                        [checked]="data.canViewSalary"
                      />
                      <span class="checkmark"></span>
                    </label>
                    <span (click)="removeSelection($event, data.email)">{{ data.email }}</span>
                  </div>
                </ng-template>
              </ejs-listview>
            </div>
          </div>
          <h6 class="reassignment-notes">Ressignment Notes</h6>
          <ejs-textbox
            #notesTextbox
            [multiline]="true"
            [(ngModel)]="notes"
            placeholder="Enter notes"
            width="100%"
          ></ejs-textbox>
        </div>
        <div class="buttons">
          <button class="action-button btn btn-success" (click)="confirmAction(reassignDialog, 'reassign')">
            Confirm
          </button>
          <button class="action-button btn btn-danger" (click)="closeDialog(reassignDialog)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>

  <ejs-dialog #approveDialog header="Approve Candidate" width="400" [isModal]="true" [visible]="false">
    <ng-template #content>
      <div class="dialog-content">
        <div class="form-group">
          <ejs-textbox
            #notesTextbox
            [multiline]="true"
            [(ngModel)]="notes"
            placeholder="Enter notes"
            width="100%"
          ></ejs-textbox>
        </div>
        <div class="form-group">
          <h6>Post Interview Form</h6>
          <ejs-uploader
            #defaultupload
            id="defaultfileupload"
            dropArea="ejs-uploader"
            (select)="onFileRemove($event)"
            (selected)="setPostInterviewDoc($event)"
            allowedExtensions=".pdf,.docx"
          ></ejs-uploader>
        </div>
        <div class="buttons">
          <button class="action-button btn btn-success" (click)="confirmAction(approveDialog, 'approve')">
            Approve
          </button>
          <button class="action-button btn btn-danger" (click)="closeDialog(approveDialog)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>

  <ejs-dialog #rejectDialog header="Reject Candidate" width="400" [isModal]="true" [visible]="false">
    <ng-template #content>
      <div class="dialog-content">
        <div class="form-group">
          <ejs-textbox
            #notesTextbox
            [multiline]="true"
            [(ngModel)]="notes"
            placeholder="Enter notes"
            width="100%"
          ></ejs-textbox>
        </div>
        <div class="form-group">
          <h6>Post Interview Form</h6>
          <ejs-uploader
            #defaultupload
            id="defaultfileupload"
            dropArea="ejs-uploader"
            (select)="onFileRemove($event)"
            (selected)="setPostInterviewDoc($event)"
            allowedExtensions=".pdf,.docx"
          ></ejs-uploader>
        </div>
        <div class="buttons">
          <button class="action-button btn btn-success" (click)="confirmAction(rejectDialog, 'reject')">Reject</button>
          <button class="action-button btn btn-danger" (click)="closeDialog(rejectDialog)">Cancel</button>
        </div>
      </div>
    </ng-template>
  </ejs-dialog>
</div>
