import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getEnumKeyByEnumValue, showErrorDialog } from '../shared/utils';
import { environment } from '../../environments/environment';
import { ApplicationDto } from '../model/application-model';
import { ApplicationStatus } from '../model/enums/application-status';
import { Location } from '../model/enums/location';
import { CvAnalysisResultModel } from '../model/cv-analysis-result-model';


@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/applications`;
  private refreshTrigger = new BehaviorSubject<void>(void 0);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
  ) { }


  public getApplication$(id: string): Observable<ApplicationDto> {

    return this.http.get<ApplicationDto>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public deleteApplication$(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getApplicationByInterviewId$(interviewId: string): Observable<ApplicationDto> {

    return this.http.get<ApplicationDto>(`${this.apiEndpoint}/interview/${interviewId}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public createApplication$(application: FormData): Observable<ApplicationDto> {
    var email = application.get("azureB2CUserEmail");

    return this.http.post<ApplicationDto>(`${this.apiEndpoint}/${email}`, application).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public approveAfterInterview$(update: FormData): Observable<ApplicationDto> {
    var id = update.get("id");
    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/approve-after-interview`, update).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public analyzeCV$(update: FormData): Observable<CvAnalysisResultModel> {
    return this.http.post<CvAnalysisResultModel>(`${this.apiEndpoint}/analyze-cv`, update).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public rejectAfterInterview$(update: FormData): Observable<ApplicationDto> {
    var id = update.get("id");

    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/reject-after-interview`, update).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public approveHRPreScreening$(id: string, hrNotes: string): Observable<ApplicationDto> {
    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/approve-hr-pre-screening?notes=${hrNotes}`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public approveToInterview$(id: string): Observable<ApplicationDto> {
    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/approve-to-interview`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updateApplicationStatus$(id: string, status: ApplicationStatus): Observable<ApplicationDto> {
    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/update-status?status=${status}`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public rejectApplication$(id: string, notes: string): Observable<ApplicationDto> {

    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/reject?comment=${notes}`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public makeoffer$(id: string): Observable<ApplicationDto> {

    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/make-offer`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public acceptOffer$(id: string): Observable<ApplicationDto> {

    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/accept-offer`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public rejectOffer$(id: string): Observable<ApplicationDto> {

    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/reject-offer`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public putOnHold$(id: string, notes: string): Observable<ApplicationDto> {

    return this.http.put<ApplicationDto>(`${this.apiEndpoint}/${id}/put-on-hold?comment=${notes}`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }


  getAllApplications$(): Observable<ApplicationDto[]> {

    return this.http.get<ApplicationDto[]>(this.apiEndpoint).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  getAllDubaiApplications$(): Observable<ApplicationDto[]> {

    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<ApplicationDto[]>(this.apiEndpoint + "/location/" + Location.Dubai).pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  getAllHKApplications$(): Observable<ApplicationDto[]> {
    const locationKey = getEnumKeyByEnumValue(Location, Location.HongKong);

    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<ApplicationDto[]>(this.apiEndpoint + "/location/" + locationKey).pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  getAllGenevaApplications$(): Observable<ApplicationDto[]> {

    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<ApplicationDto[]>(this.apiEndpoint + "/location/" + Location.Geneva).pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  getUserApplications$(): Observable<ApplicationDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<ApplicationDto[]>(this.apiEndpoint + "/user-applications").pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  getUserApplicationInterviews$(): Observable<ApplicationDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<ApplicationDto[]>(this.apiEndpoint + "/user-upcoming-application-interviews").pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  total$(): Observable<number> {
    return this.getAllApplications$().pipe(
      map((positions) => positions.length),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
