export enum ApplicationStatus {
  Submitted = 'Submitted',
  PassedHRPreScreening = 'Passed HR PreScreening',
  OnHold = 'On Hold',
  ProceedToInterview = 'Proceed To Interview',
  PotentialOffer = 'Potential Offer',
  Rejected = 'Rejected',
  OfferMade = 'Offer Made',
  OfferAccepted = 'Offer Accepted',
  OfferDeclined = 'Offer Declined',
}
