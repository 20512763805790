import { AbstractControl, FormArray, ValidationErrors, ValidatorFn } from '@angular/forms';
import { RecruitingMode } from '../model/enums/recruiting-mode';
import { PositionDto } from '../model/position-model';
import { QuestionDto } from '../model/question-model';

export function validateInterviewersArray(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const interviewers = control.get('interviewers') as FormArray;
    return interviewers && interviewers.length === 0 ? { noInterviewers: true } : null;
  };
}

export function validateSalaryRange(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const minSalary = control.get('salaryMin')?.value;
    const maxSalary = control.get('salaryMax')?.value;

    if (minSalary !== null && maxSalary !== null && minSalary >= maxSalary) {
      return { invalidSalaryRange: true };
    }
    return null;
  };
}

export function validateRecruitingMinYesCount(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const recruitingMode = control.get('recruitingMode')?.value;

    const recruitingModeValue = getRecruitingModeFromKey(recruitingMode)
    const recruitingMinYesCount = control.get('recruitingMinYesCount')?.value;    
    
    if (recruitingModeValue === RecruitingMode.MinYes && (!recruitingMinYesCount || recruitingMinYesCount <= 0)) {      
      return { invalidRecruitingMinYesCount: true };
    }
    return null;
  };
}

export function validateMinYesCountWithInterviewers(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const recruitingMode = control.get('recruitingMode')?.value;
    const recruitingModeValue = getRecruitingModeFromKey(recruitingMode);
    const recruitingMinYesCount = control.get('recruitingMinYesCount')?.value;

    if (recruitingModeValue !== RecruitingMode.MinYes) {
      return null;
    }

    const rounds = control.get('rounds') as FormArray;
    if (!rounds || rounds.length === 0) {
      return null;
    }

    let minInterviewers = Infinity;
    rounds.controls.forEach((round) => {
      const interviewers = (round.get('interviewers') as FormArray)?.length;
      if (interviewers < minInterviewers) {
        minInterviewers = interviewers;
      }
    });

    if (recruitingMinYesCount > minInterviewers) {
      return { minYesCountExceedsInterviewers: true };
    }

    return null;
  };
}

export function validateQuestionsArray(): ValidatorFn {

  return (control: AbstractControl): ValidationErrors | null => {


    const questionsArray = control.value as QuestionDto[];

    if (questionsArray != null) {
      for (let i = 0; i < questionsArray.length; i++) {
        const question = questionsArray[i];

        if (question.question && question.question.trim().length < 20) {
          return { invalidQuestionLength: true };
        }

      }
    }
    return null;
  };
}


function getRecruitingModeFromKey(key: string): RecruitingMode | undefined {
  return RecruitingMode[key as keyof typeof RecruitingMode];
}