<div class="container">
  <app-header></app-header>

  <div class="content">
    <!--This is to avoid reload during acquireTokenSilent() because of hidden iframe -->
    @if (!isIframe) {
      <router-outlet></router-outlet>
    }
  </div>
</div>
