import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { NGXLogger } from 'ngx-logger';
import { showErrorDialog } from '../shared/utils';
import { catchError, Observable, tap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BlobStorageService {
  private apiEndpoint = `${environment.apiEndpoint}/api/applications`;

  constructor(private http: HttpClient, private logger: NGXLogger, private errorDialog: MatDialog) {}

  uploadFile(file: File): Observable<string> {
    const formData = new FormData();
    formData.append('file', file);
  
    return this.http.put<string>(this.apiEndpoint + '/uploadBlob', formData).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
