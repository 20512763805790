import { Routes } from '@angular/router';
import { LandingComponent } from './home/landing.component';
import { InterviewsListComponent } from './interview/list/interview-list.component';
import { MsalGuard } from '@azure/msal-angular';
import { PositionListComponent } from './position/list/position-list.component';
import { CreatePositionComponent } from './position/create/create-position.component';
import { UploadApplicationComponent } from './application/upload/upload-application.component';
import { ApplicationListComponent } from './application/list/application-list.component';
import { ApplicationDetailComponent } from './application/detail/application-detail.component';
import { PositionDetailComponent } from './position/detail/position-detail.component';
import { InterviewDetailComponent } from './interview/detail/interview-detail.component';
import { UpcomingInterviewsComponent } from './interview/upcoming/upcoming-interviews.component';

export const routes: Routes = [
  { path: 'home', redirectTo: '/positions' },
  { path: '', pathMatch: 'full', component: LandingComponent },
  {
    path: 'positions',
    children: [
      { path: '', component: PositionListComponent, canActivate: [MsalGuard] },
      { path: 'create', component: CreatePositionComponent, canActivate: [MsalGuard] },
      { path: 'details/:id', component: PositionDetailComponent, canActivate: [MsalGuard] }
    ]
  },
  {
    path: 'applications',
    children: [
      { path: '', component: ApplicationListComponent, canActivate: [MsalGuard] },
      { path: 'upload', component: UploadApplicationComponent, canActivate: [MsalGuard] },
      { path: 'details/:id', component: ApplicationDetailComponent, canActivate: [MsalGuard] }
    ]
  },
  {
    path: 'interviews',
    children: [
      { path: '', component: InterviewsListComponent, canActivate: [MsalGuard] },
      { path: 'details/:id', component: InterviewDetailComponent, canActivate: [MsalGuard] },
      { path: 'upcoming', component: UpcomingInterviewsComponent, canActivate: [MsalGuard] }
    ]
  },

];
