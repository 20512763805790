import { MsalGuardConfiguration, MsalInterceptorConfiguration } from '@azure/msal-angular';
import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import { environment } from '../environments/environment';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function loggerCallback(logLevel: LogLevel, message: string): void {
  if (logLevel > LogLevel.Info) {
    console.log(message);
  }
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.clientId,
      authority: `https://login.microsoftonline.com/${environment.tenant}/`,
      redirectUri: '/',
      postLogoutRedirectUri: '/'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set(`${environment.apiEndpoint}/api/positions`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/users`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/interviews`, environment.consentScopes);
  protectedResourceMap.set(`${environment.apiEndpoint}/api/applications`, environment.consentScopes);

  protectedResourceMap.set(environment.graphEndpoint, ['User.Read', 'User.ReadBasic.All']);

  if (environment.staffInitialsApi.endpoint !== null && environment.staffInitialsApi.consentScopes !== null) {
    protectedResourceMap.set(
      `${environment.staffInitialsApi.endpoint}/api/users`,
      environment.staffInitialsApi.consentScopes
    );
  }

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: environment.consentScopes
    },
    loginFailedRoute: '/login-failed'
  };
}
