import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { PositionDto } from '../../model/position-model';
import { PositionFormComponent } from '../form/position-form.component';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ignoreElements } from 'rxjs';

@Component({
  selector: 'app-read-position-details',
  templateUrl: './read-position-detail.component.html',
  styleUrl: './read-position-detail.component.css',
  standalone: true,
  imports: [
    CommonModule,
    ListViewModule
  ],
  providers: []
})
export class ReadPositionDetailComponent {
  @Input() position: PositionDto;


  constructor(
  ) { }

  ngOnInit(): void { }

}
