import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { HtmlEditorService, ImageService, LinkService, RichTextEditorAllModule, RichTextEditorComponent, RichTextEditorModule, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';
import { QuestionDto } from '../../../../model/question-model';
import { AnswerFormConfig } from '../../../../model/form-configs/answer-form-config';

interface t {
    question: QuestionDto;
    answer: FormGroup<AnswerFormConfig>
}

@Component({
    selector: 'app-question-answer',
    templateUrl: './question-answer.component.html',
    styleUrls: ['./question-answer.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RichTextEditorAllModule,
        CheckBoxModule,
    ],
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class QuestionAnswerComponent {
    @Input() aggregateInterface: t;
    public isMandatory: boolean = false;
    public tools: object = {
        items: ['Undo', 'Redo', '|',
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontSize', '|',
            'Formats', 'Alignments', '|',
            'CreateLink', 'Image', '|', 'SourceCode']
    };
    public iframe: object = { enable: true };
    public height: number = 150;

    ngOnInit(): void {
        if (this.aggregateInterface.question.isMandatory) {
            this.aggregateInterface.answer.get('answer')?.setValidators(Validators.required);
            this.aggregateInterface.answer.get('answer')?.updateValueAndValidity();
        }
    }

    onSubmit(): void {
        alert('Form submitted successfully');
    }
}
