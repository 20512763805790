import { TENANT_PROD } from '../app/shared/constants';

const apiEndpoint = 'https://cigpcareersapiuat.azurewebsites.net';
const graphEndpoint = 'https://graph.microsoft.com/v1.0';

export const environment = {
  env: 'uat',
  consentScopes: ['api://8c9eb414-368a-4795-be06-0d6eb5632981/user_impersonation'],
  apiEndpoint,
  graphEndpoint,
  clientId: '1a8d86b5-cc05-4f71-9211-4213f685521b',
  tenant: TENANT_PROD,
  appInsights: {
    instrumentationKey: '2d60f0fa-4f58-48ea-a49e-eaaa2f0eb6ca'
  },
  staffInitialsApi: {
    endpoint: null,
    consentScopes: null 
  }
};
