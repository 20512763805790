export const TENANT_DEV = '86df0574-f6b7-4b61-9727-31891fa0d5c1';
export const TENANT_PROD = '6f41eebe-f4f6-41f6-9d01-03d70104f18c';

export const CIGP_COLORS = [
  '#56725A',
  '#5A7865',
  '#5F7E71',
  '#63837D',
  '#678789',
  '#6C858E',
  '#708294',
  '#757F99',
  '#797B9F',
  '#847EA5',
  '#9282AA',
  '#A086B0',
  '#AE8BB5',
  '#BB8FB8',
  '#C094B3',
  '#C698AE',
  '#CB9DA9',
  '#D1A1A3',
  '#D6AFA6',
  '#DCBFAB',
  '#E1CFAF'
];

export const ROLE_HR_HK = 'HR.HK';
export const ROLE_HR_GENEVA = 'HR.Geneva';
export const ROLE_HR_DUBAI = 'HR.Dubai';
export const ROLE_STAFF = 'Staff';



