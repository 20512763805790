<div *ngIf="loading; else content">
  <app-spinner></app-spinner>
</div>
<ng-template #content>
  <div class="application-details-container" *ngIf="application">
    <!-- Candidate Name -->
    <h2>{{ application.candidate.displayName }}</h2>

    <!-- Application Status -->
    <div class="application-status">
      <div class="status-column">
        <span class="status-label">Application Status: </span>
        <div class="status-badge">{{ getApplicationStatusValue(application.status) }}</div>
      </div>
      <div class="status-column">
        <span class="status-label">Requires HR PreScreening: </span>
        <div class="status-badge">{{ application.position.hrPrescreening }}</div>
      </div>
    </div>

    <!-- Buttons for actions -->
    <div class="action-buttons">
      <button
        class="action-button btn btn-success"
        (click)="openDialog(approveHrPreScreeningDialog)"
        [hidden]="!showApproveHRPreScreeeningButton()"
        [disabled]="!canClickApproveHRPreScreening()"
      >
        Approve HR Pre Screening
      </button>
      <button
        class="action-button btn btn-success"
        (click)="approveToInterview()"
        [hidden]="!showApproveToFirstInterviewButton()"
        [disabled]="!canClickApproveToFirstInterview()"
      >
        Approve to Create First Interview
      </button>
      <button
        class="action-button btn btn-success"
        (click)="makeOffer()"
        [hidden]="application.status !== 'PotentialOffer'"
        [disabled]="!canFinalizeApplication()"
      >
        Make Offer
      </button>
      <button
        class="action-button btn btn-success"
        (click)="acceptOffer()"
        [hidden]="application.status !== 'OfferMade'"
        [disabled]="!canFinalizeApplication()"
      >
        Accepted Offer
      </button>
      <button
        class="action-button btn btn-danger"
        (click)="rejectOffer()"
        [hidden]="application.status !== 'OfferMade'"
        [disabled]="!canFinalizeApplication()"
      >
        Rejected Offer
      </button>
      <button class="action-button btn btn-danger" (click)="openDialog(rejectDialog)" [disabled]="!canClickReject()">
        Reject
      </button>
      <button
        class="action-button btn btn-danger"
        [hidden]="!canClickDeleteApplication()"
        (click)="openDialog(deleteDialog)"
      >
        Delete
      </button>
    </div>

    <ejs-dialog #rejectDialog header="Force Reject Candidate" width="800" [isModal]="true" [visible]="false">
      <ng-template #content>
        <div class="dialog-content">
          <div class="form-group">
            <ejs-richtexteditor
              #notesTextbox
              id="iframeRTE"
              [height]="height"
              [(ngModel)]="notes"
              placeholder="Enter notes"
            >
            </ejs-richtexteditor>
          </div>
          <div class="buttons">
            <button class="action-button btn btn-success" (click)="confirmAction(rejectDialog, 'reject')">
              Confirm
            </button>
            <button class="action-button btn btn-danger" (click)="closeDialog(rejectDialog)">Cancel</button>
          </div>
        </div>
      </ng-template>
    </ejs-dialog>

    <ejs-dialog
      #approveHrPreScreeningDialog
      header="Approve HR Pre Screening"
      width="800"
      [isModal]="true"
      [visible]="false"
    >
      <ng-template #content>
        <div class="dialog-content">
          <div class="form-group">
            <ejs-richtexteditor
              #notesTextbox
              id="iframeRTE"
              [height]="height"
              [(ngModel)]="notes"
              placeholder="Enter notes"
            >
            </ejs-richtexteditor>
          </div>
          <div class="buttons">
            <button
              class="action-button btn btn-success"
              (click)="confirmAction(approveHrPreScreeningDialog, 'approveHrPreScreeningDialog')"
            >
              Confirm
            </button>
            <button class="action-button btn btn-danger" (click)="closeDialog(approveHrPreScreeningDialog)">
              Cancel
            </button>
          </div>
        </div>
      </ng-template>
    </ejs-dialog>

    <ejs-dialog
      #deleteDialog
      header="Delete Application"
      width="800"
      [isModal]="true"
      [visible]="false"
    >
      <ng-template #content>
        <div class="dialog-content">
          <div class="form-group">
            
          </div>
          <div class="buttons">
            <button
              class="action-button btn btn-success"
              (click)="confirmAction(deleteDialog, 'delete')"
            >
              Confirm
            </button>
            <button class="action-button btn btn-danger" (click)="closeDialog(deleteDialog)">
              Cancel
            </button>
          </div>
        </div>
      </ng-template>
    </ejs-dialog>

    <!-- Candidate Details -->
    <div class="user-information card">
      <h3>Candidate Details</h3>
      <ul>
        <li><strong>Email:</strong> {{ application.candidate.mail }}</li>
        <p>
          <strong>CV: </strong>
          <a
            *ngIf="application.cvUrl.split('?')[0].endsWith('.pdf')"
            [href]="application.cvUrl"
            target="_blank"
            rel="noopener noreferrer"
          >
            Open
          </a>
        </p>
        <p *ngIf="application.additionalDoc1Url">
          <strong>Additional Doc 1: </strong
          ><a [href]="application.additionalDoc1Url" target="_blank" rel="noopener noreferrer">Open</a>
        </p>
        <p *ngIf="application.additionalDoc2Url">
          <strong>Additional Doc 2: </strong
          ><a [href]="application.additionalDoc2Url" target="_blank" rel="noopener noreferrer">Open</a>
        </p>
        <p *ngIf="application.additionalDoc3Url">
          <strong>Additional Doc 3: </strong
          ><a [href]="application.additionalDoc3Url" target="_blank" rel="noopener noreferrer">Open</a>
        </p>
        <li><strong>Phone:</strong> {{ application.candidate.mobilePhone }}</li>
        <li><strong>Location:</strong> {{ application.candidate.country }}</li>
        <li><strong>Availability:</strong> {{ application.availability }}</li>
        <li><strong>Most Recent Company:</strong> {{ application.mostRecentCompany }}</li>
        <li><strong>Most Recent Job Title:</strong> {{ application.mostRecentJobTitle }}</li>
      </ul>
    </div>

    <!-- Position Details -->
    <div class="position-details card">
      <h3>Position Details</h3>
      <ul>
        <li><strong>Job Title:</strong> {{ application.position.jobTitle }}</li>
        <li><strong>Location:</strong> {{ getLocationValue(application.position.location) }}</li>
        <li><strong>Team:</strong> {{ getTeamValue(application.position.team) }}</li>
        <!-- Add other position details as needed -->
      </ul>
    </div>
    <!-- Application Details -->
    <div class="application-details card">
      <h3 class="mb-4">Application Details</h3>
      <div class="important-fields mb-5">
        <p>
          <strong>Expected Salary:</strong> {{ application.expectedSalary }}
          {{ application.position.salaryCurrency }}
        </p>
        <p><strong>HR Notes:</strong> <span [innerHTML]="application.hrNotes"></span></p>
      </div>

      <div class="horizontal-line bg-primary mb-5"></div>

      <div class="interview-process-details" style="text-align: center; font-size: 14px; margin-bottom: 50px">
        <div class="mb-5"><h4>Interview Process Details</h4></div>
        <div class="interview-details">
          <h5 class="mb-4">Interviews</h5>

          <div class="interview-list">
            <div class="interview-row" *ngFor="let interview of application.interviews">
              <div class="interview-card-container">
                <app-interview-card
                  [application]="application"
                  [interview]="interview"
                  (applicationUpdated)="onApplicationUpdated($event)"
                  (click)="navigateToInterviewDetail(interview)"
                  class="clickable-card"
                >
                </app-interview-card>
              </div>
            </div>
          </div>
        </div>

        <div class="horizontal-line bg-primary mb-5"></div>
      </div>

      <!-- Answers to Questions -->
      <div class="card font-size: 14px; margin-bottom: 10px" *ngIf="application.answers.length > 0">
        <div class="interview-process-details" style="text-align: center">
          <div class="mb-5"><h4>Answers</h4></div>
        </div>

        <div *ngFor="let answer of application.answers" class="question-answer">
          <p>
            <strong [innerHTML]="answer.question.question"></strong>
          </p>
          <p [innerHTML]="answer.answer"></p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
