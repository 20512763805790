import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CanViewApplicationsComponent } from '../application/can-view-applications.component';
import { CanWriteApplicationsComponent } from '../application/can-write-applications.component';

@Component({
  selector: 'of-applications-nav-menu',
  standalone: true,
  imports: [RouterModule, CanViewApplicationsComponent, CanWriteApplicationsComponent],
  template: `
    <of-can-view-applications>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Applications
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/applications/']"><i class="bi bi-list"></i> List</a>
          </li>
          <of-can-write-application>
            <li>
              <a class="dropdown-item" [routerLink]="['/applications', 'upload']"
                ><i class="bi bi-plus-lg"></i> Upload</a
              >
            </li>
          </of-can-write-application>
        </ul>
      </li>
    </of-can-view-applications>
  `
})
export class ApplicationsNavMenuComponent {}
