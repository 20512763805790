<div class="parent-container">
  <div class="app-interview-card-container">
    <div class="card-header" [ngClass]="getBackgroundColor()">
      <h5 class="header-title">Interview for Round {{ interview.round.roundNumber }}</h5>
    </div>
    <div class="card-body">
      <!-- Interviewers Information -->
      <div class="info-card">
        <div class="font">Interviewers:</div>
        <ejs-listview [dataSource]="interview.round.interviewers" [fields]="{ text: 'name' }">
          <ng-template #template let-data>
            <div class="list-item">{{ data.displayName }}</div>
          </ng-template>
        </ejs-listview>
      </div>

      <!-- Interview Status -->
      <div class="info-card">
        <div class="font">Status:</div>
        <div class="small-font">{{ interview.status }}</div>
      </div>
    </div>

    <!-- Approved By -->
    <div class="card-body">
      <div class="info-card">
        <div class="font">Approved By:</div>
        <ejs-listview [dataSource]="interview.approvedBy" [fields]="{ text: 'name' }">
          <ng-template #template let-data>
            <div class="list-item">{{ data }}</div>
          </ng-template>
        </ejs-listview>
      </div>

      <!-- Rejected By -->
      <div class="info-card">
        <div class="font">Rejected By:</div>
        <ejs-listview [dataSource]="interview.rejectedBy" [fields]="{ text: 'name' }">
          <ng-template #template let-data>
            <div class="list-item">{{ data }}</div>
          </ng-template>
        </ejs-listview>
      </div>

      <div class="info-card" *ngIf="hasInterviewNotes()">
        <div class="font">Interviewer Notes:</div>
        <ejs-listview [dataSource]="interviewersWithNotes" [fields]="{ text: 'interviewer.displayName' }">
          <ng-template #template let-data>
            <div class="list-item" [title]="data.interviewNotes">
              <strong>{{ data.interviewerDisplayName }}: </strong>
              <span class="note-content">{{ data.interviewNotes }}</span>
            </div>
          </ng-template>
        </ejs-listview>
      </div>
    </div>
  </div>
</div>
