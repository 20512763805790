<div *ngIf="loading; else content">
  <app-spinner></app-spinner>
</div>
<ng-template #content>
  <div class="container">
    <div class="filter-row mb-5">
      <div class="select-container">
        <ejs-dropdownlist
          id="positions"
          placeholder="Position"
          [dataSource]="positions"
          (change)="applyFilter($event.value, 'position')"
          [showClearButton]="true"
          [fields]="fields"
          [itemTemplate]="itemTemplate"
          width="350px"
        >
        </ejs-dropdownlist>
        <ng-template #itemTemplate let-data>
          {{ data.jobTitle }} - {{ getTeamValue(data.team) }} | {{ getLocationValue(data.location) }}
        </ng-template>
      </div>

      <div class="select-container">
        <ejs-dropdownlist
          id="status"
          placeholder="Status"
          [dataSource]="statuses"
          (change)="applyFilter($event.value, 'status')"
          [showClearButton]="true"
          width="250px"
        >
        </ejs-dropdownlist>
      </div>
    </div>

    <ejs-grid
      [dataSource]="filteredInterviews"
      [allowPaging]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [enableHover]="true"
      [allowResizing]="true"
      [pageSettings]="pageSettings"
      [allowSelection]="false"
      height="700px"
    >
      <e-columns>
        <e-column field="id" headerText="ID" width="55" cssClass="id-cell" textAlign="Center">
          <ng-template #template let-data>
            <a class="clickable-link" (click)="navigateToInterviewDetail(data)">{{ data.id }}</a>
          </ng-template>
        </e-column>
        <e-column
          field="application.candidate.displayName"
          headerText="Candidate Name"
          width="150"
          cssClass="candidate-name-cell"
          textAlign="Center"
        >
          <ng-template #template let-data *ngIf="applications">
            {{ data.application.candidate.displayName }}
          </ng-template>
        </e-column>
        <e-column
          field="application.created"
          headerText="Date of Application"
          width="150"
          cssClass="application-date-cell"
          textAlign="Center"
        >
          <ng-template #template let-data>
            {{ data.application.created | date }}
          </ng-template>
        </e-column>
        <e-column field="status" headerText="Status" format="yMd" width="100" textAlign="Center"></e-column>
        <e-column headerText="Round" width="50" textAlign="Center">
          <ng-template #template let-data>
            {{ data.round?.roundNumber }}
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</ng-template>
