import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDto } from '../../model/application-model';
import { ApplicationsService } from '../../services/applications.service';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ApplicationStatus } from '../../model/enums/application-status';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { PositionDto } from '../../model/position-model';
import { PositionsService } from '../../services/positions.service';
import { PositionFormService } from '../../services/position-form.service';
import { showErrorDialog, showSuccessSnackbar } from '../../shared/utils';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { PositionFormComponent } from '../form/position-form.component';
import { CareersAuthService } from '../../../auth/careers-auth.service';
import { ReadPositionDetailComponent } from '../read-only-detail/read-position-detail.component';
import { SpinnerComponent } from '../../shared/spinner.component';

@Component({
  selector: 'app-position-detail',
  templateUrl: './position-detail.component.html',
  styleUrl: './position-detail.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PositionFormComponent,
    DialogModule,
    PositionFormComponent,
    ReadPositionDetailComponent,
    SpinnerComponent
  ],
  providers: []
})
export class PositionDetailComponent {
  isEditMode: boolean = true;
  position: PositionDto;
  loading: boolean = true;
  isHR: boolean;
  @ViewChild('deleteDialog') deleteDialog: DialogComponent;

  constructor(
    private positionsService: PositionsService, private router: Router, private positionsFormService: PositionFormService,
    private route: ActivatedRoute,
    private authService: CareersAuthService
  ) {
    this.loadPosition();
  }

  ngOnInit(): void {
    this.isHR = this.authService.isHR();
  }

  loadPosition(): void {
    const urlSegments = this.route.snapshot.url;
    const lastSegmentIndex = urlSegments.length - 1;
    const positionId = urlSegments[lastSegmentIndex].path;

    this.positionsService.getPosition$(positionId).subscribe((position) => {
      this.position = position;
      this.loading = false;
    });
  }

  confirmAction(dialog: DialogComponent, action: string) {
    if (action === 'delete') {
      this.deletePosition();
    }
    this.closeDialog(dialog);
  }

  canClickDeletePosition() {
    return this.authService.isHR();
  }

  openDialog(dialog: DialogComponent) {
    dialog.show();
  }

  closeDialog(dialog: DialogComponent) {
    dialog.hide();
  }

  openPosition() {
    this.positionsService.openPosition$(this.position.id).subscribe((pos) => {
      this.position = pos;
    })
  }

  closePosition() {
    this.positionsService.closePosition$(this.position.id).subscribe((pos) => {
      this.position = pos;
    })
  }

  mergeFormDataWithPositionDto(
    formPosition: PositionDto,
    existingPosition: PositionDto
  ): PositionDto {
    return {
      ...existingPosition,
      ...formPosition,
      createdBy: existingPosition.createdBy,
      created: existingPosition.created,
    };
  }

  deletePosition() {
    this.positionsService.deletePosition$(this.position.id).subscribe(() => {
      this.router.navigate(['positions']);
    });
  }

  onSubmit(formData: FormGroup) {
    if (formData.valid) {
      const pos = this.mergeFormDataWithPositionDto(formData.value, this.position)
      this.positionsService.updatePosition$(this.position.id, pos).subscribe(() => {
        this.positionsFormService.reset(formData);
        this.router.navigate(["positions"]);
      });
    }
  }

}
