import * as i0 from '@angular/core';
import { Directive, ContentChildren, Component, ChangeDetectionStrategy, ContentChild, NgModule } from '@angular/core';
import { ComplexBase, setValue, ArrayBase, ComponentBase, ComponentMixins } from '@syncfusion/ej2-angular-base';
import { __decorate } from 'tslib';
import { DropDownButton, SplitButton, ProgressButton } from '@syncfusion/ej2-splitbuttons';
export * from '@syncfusion/ej2-splitbuttons';
import { CommonModule } from '@angular/common';
const _c0 = ["ejs-dropdownbutton", ""];
const _c1 = ["*"];
const _c2 = ["ejs-progressbutton", ""];
let input$1 = ['disabled', 'iconCss', 'id', 'separator', 'text', 'url'];
let outputs$4 = [];
class DropDownButtonItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$4);
    this.directivePropList = input$1;
  }
}
DropDownButtonItemDirective.ɵfac = function DropDownButtonItemDirective_Factory(t) {
  return new (t || DropDownButtonItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
DropDownButtonItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DropDownButtonItemDirective,
  selectors: [["e-dropdownbuttonitem"]],
  inputs: {
    disabled: "disabled",
    iconCss: "iconCss",
    id: "id",
    separator: "separator",
    text: "text",
    url: "url"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownButtonItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-dropdownbuttonitems>e-dropdownbuttonitem',
      inputs: input$1,
      outputs: outputs$4,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * DropDownButtonItem Array Directive
 * @private
 */
class DropDownButtonItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
DropDownButtonItemsDirective.ɵfac = function DropDownButtonItemsDirective_Factory(t) {
  return new (t || DropDownButtonItemsDirective)();
};
DropDownButtonItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: DropDownButtonItemsDirective,
  selectors: [["e-dropdownbuttonitems"]],
  contentQueries: function DropDownButtonItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, DropDownButtonItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownButtonItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-dropdownbutton>e-dropdownbuttonitems',
      queries: {
        children: new ContentChildren(DropDownButtonItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$2 = ['closeActionEvents', 'content', 'createPopupOnClick', 'cssClass', 'disabled', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'iconCss', 'iconPosition', 'items', 'locale', 'target'];
const outputs$3 = ['beforeClose', 'beforeItemRender', 'beforeOpen', 'close', 'created', 'open', 'select'];
const twoWays$2 = [];
/**
 * Represents the Angular DropDownButton Component.
 * ```html
 * <button ejs-dropdownbutton>DropDownButton</button>
 * ```
 */
let DropDownButtonComponent = class DropDownButtonComponent extends DropDownButton {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.containerContext.ngAfterContentChecked(this);
  }
};
DropDownButtonComponent.ɵfac = function DropDownButtonComponent_Factory(t) {
  return new (t || DropDownButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
DropDownButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: DropDownButtonComponent,
  selectors: [["", "ejs-dropdownbutton", ""]],
  contentQueries: function DropDownButtonComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, DropDownButtonItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    closeActionEvents: "closeActionEvents",
    content: "content",
    createPopupOnClick: "createPopupOnClick",
    cssClass: "cssClass",
    disabled: "disabled",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    iconCss: "iconCss",
    iconPosition: "iconPosition",
    items: "items",
    locale: "locale",
    target: "target"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeItemRender: "beforeItemRender",
    beforeOpen: "beforeOpen",
    close: "close",
    created: "created",
    open: "open",
    select: "select"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c0,
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function DropDownButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
DropDownButtonComponent = __decorate([ComponentMixins([ComponentBase])], DropDownButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownButtonComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-dropdownbutton]',
      inputs: inputs$2,
      outputs: outputs$3,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(DropDownButtonItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the DropDownButton component.
 */
class DropDownButtonModule {}
DropDownButtonModule.ɵfac = function DropDownButtonModule_Factory(t) {
  return new (t || DropDownButtonModule)();
};
DropDownButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropDownButtonModule
});
DropDownButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [DropDownButtonComponent, DropDownButtonItemDirective, DropDownButtonItemsDirective],
      exports: [DropDownButtonComponent, DropDownButtonItemDirective, DropDownButtonItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the DropDownButton component with providers.
 */
class DropDownButtonAllModule {}
DropDownButtonAllModule.ɵfac = function DropDownButtonAllModule_Factory(t) {
  return new (t || DropDownButtonAllModule)();
};
DropDownButtonAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: DropDownButtonAllModule
});
DropDownButtonAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, DropDownButtonModule], DropDownButtonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(DropDownButtonAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, DropDownButtonModule],
      exports: [DropDownButtonModule],
      providers: []
    }]
  }], null, null);
})();
let input = ['disabled', 'iconCss', 'id', 'separator', 'text', 'url'];
let outputs$2 = [];
class SplitButtonItemDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$2);
    this.directivePropList = input;
  }
}
SplitButtonItemDirective.ɵfac = function SplitButtonItemDirective_Factory(t) {
  return new (t || SplitButtonItemDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
SplitButtonItemDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SplitButtonItemDirective,
  selectors: [["e-splitbuttonitem"]],
  inputs: {
    disabled: "disabled",
    iconCss: "iconCss",
    id: "id",
    separator: "separator",
    text: "text",
    url: "url"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitButtonItemDirective, [{
    type: Directive,
    args: [{
      selector: 'e-splitbuttonitems>e-splitbuttonitem',
      inputs: input,
      outputs: outputs$2,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * SplitButtonItem Array Directive
 * @private
 */
class SplitButtonItemsDirective extends ArrayBase {
  constructor() {
    super('items');
  }
}
SplitButtonItemsDirective.ɵfac = function SplitButtonItemsDirective_Factory(t) {
  return new (t || SplitButtonItemsDirective)();
};
SplitButtonItemsDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: SplitButtonItemsDirective,
  selectors: [["e-splitbuttonitems"]],
  contentQueries: function SplitButtonItemsDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, SplitButtonItemDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitButtonItemsDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-splitbutton>e-splitbuttonitems',
      queries: {
        children: new ContentChildren(SplitButtonItemDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
const inputs$1 = ['closeActionEvents', 'content', 'createPopupOnClick', 'cssClass', 'disabled', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'iconCss', 'iconPosition', 'items', 'locale', 'target'];
const outputs$1 = ['beforeClose', 'beforeItemRender', 'beforeOpen', 'click', 'close', 'created', 'open', 'select'];
const twoWays$1 = [];
/**
 * Represents the Angular SplitButton Component.
 * ```html
 * <ejs-splitbutton content='Split Button'></ejs-splitbutton>
 * ```
 */
let SplitButtonComponent = class SplitButtonComponent extends SplitButton {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.tags = ['items'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childItems;
    this.containerContext.ngAfterContentChecked(this);
  }
};
SplitButtonComponent.ɵfac = function SplitButtonComponent_Factory(t) {
  return new (t || SplitButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
SplitButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SplitButtonComponent,
  selectors: [["ejs-splitbutton"]],
  contentQueries: function SplitButtonComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, SplitButtonItemsDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childItems = _t.first);
    }
  },
  inputs: {
    closeActionEvents: "closeActionEvents",
    content: "content",
    createPopupOnClick: "createPopupOnClick",
    cssClass: "cssClass",
    disabled: "disabled",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    iconCss: "iconCss",
    iconPosition: "iconPosition",
    items: "items",
    locale: "locale",
    target: "target"
  },
  outputs: {
    beforeClose: "beforeClose",
    beforeItemRender: "beforeItemRender",
    beforeOpen: "beforeOpen",
    click: "click",
    close: "close",
    created: "created",
    open: "open",
    select: "select"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function SplitButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
SplitButtonComponent = __decorate([ComponentMixins([ComponentBase])], SplitButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitButtonComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-splitbutton',
      inputs: inputs$1,
      outputs: outputs$1,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {
        childItems: new ContentChild(SplitButtonItemsDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the SplitButton component.
 */
class SplitButtonModule {}
SplitButtonModule.ɵfac = function SplitButtonModule_Factory(t) {
  return new (t || SplitButtonModule)();
};
SplitButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SplitButtonModule
});
SplitButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SplitButtonComponent, SplitButtonItemDirective, SplitButtonItemsDirective],
      exports: [SplitButtonComponent, SplitButtonItemDirective, SplitButtonItemsDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the SplitButton component with providers.
 */
class SplitButtonAllModule {}
SplitButtonAllModule.ɵfac = function SplitButtonAllModule_Factory(t) {
  return new (t || SplitButtonAllModule)();
};
SplitButtonAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SplitButtonAllModule
});
SplitButtonAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SplitButtonModule], SplitButtonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SplitButtonAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SplitButtonModule],
      exports: [SplitButtonModule],
      providers: []
    }]
  }], null, null);
})();
const inputs = ['animationSettings', 'content', 'cssClass', 'disabled', 'duration', 'enableHtmlSanitizer', 'enableProgress', 'iconCss', 'iconPosition', 'isPrimary', 'isToggle', 'spinSettings'];
const outputs = ['begin', 'created', 'end', 'fail', 'progress'];
const twoWays = [];
/**
 * Represents the Angular ProgressButton Component.
 * ```html
 * <button ejs-progressbutton content='Progress Button'></button>
 * ```
 */
let ProgressButtonComponent = class ProgressButtonComponent extends ProgressButton {
  constructor(ngEle, srenderer, viewContainerRef, injector) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.containerContext = new ComponentBase();
  }
  ngOnInit() {
    this.containerContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.containerContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.containerContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.containerContext.ngAfterContentChecked(this);
  }
};
ProgressButtonComponent.ɵfac = function ProgressButtonComponent_Factory(t) {
  return new (t || ProgressButtonComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector));
};
ProgressButtonComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ProgressButtonComponent,
  selectors: [["", "ejs-progressbutton", ""]],
  inputs: {
    animationSettings: "animationSettings",
    content: "content",
    cssClass: "cssClass",
    disabled: "disabled",
    duration: "duration",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enableProgress: "enableProgress",
    iconCss: "iconCss",
    iconPosition: "iconPosition",
    isPrimary: "isPrimary",
    isToggle: "isToggle",
    spinSettings: "spinSettings"
  },
  outputs: {
    begin: "begin",
    created: "created",
    end: "end",
    fail: "fail",
    progress: "progress"
  },
  features: [i0.ɵɵInheritDefinitionFeature],
  attrs: _c2,
  ngContentSelectors: _c1,
  decls: 1,
  vars: 0,
  template: function ProgressButtonComponent_Template(rf, ctx) {
    if (rf & 1) {
      i0.ɵɵprojectionDef();
      i0.ɵɵprojection(0);
    }
  },
  encapsulation: 2,
  changeDetection: 0
});
ProgressButtonComponent = __decorate([ComponentMixins([ComponentBase])], ProgressButtonComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressButtonComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-progressbutton]',
      inputs: inputs,
      outputs: outputs,
      template: `<ng-content ></ng-content>`,
      changeDetection: ChangeDetectionStrategy.OnPush,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }];
  }, null);
})();

/**
 * NgModule definition for the ProgressButton component.
 */
class ProgressButtonModule {}
ProgressButtonModule.ɵfac = function ProgressButtonModule_Factory(t) {
  return new (t || ProgressButtonModule)();
};
ProgressButtonModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ProgressButtonModule
});
ProgressButtonModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressButtonModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ProgressButtonComponent],
      exports: [ProgressButtonComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the ProgressButton component with providers.
 */
class ProgressButtonAllModule {}
ProgressButtonAllModule.ɵfac = function ProgressButtonAllModule_Factory(t) {
  return new (t || ProgressButtonAllModule)();
};
ProgressButtonAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ProgressButtonAllModule
});
ProgressButtonAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ProgressButtonModule], ProgressButtonModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ProgressButtonAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ProgressButtonModule],
      exports: [ProgressButtonModule],
      providers: []
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { DropDownButtonAllModule, DropDownButtonComponent, DropDownButtonItemDirective, DropDownButtonItemsDirective, DropDownButtonModule, ProgressButtonAllModule, ProgressButtonComponent, ProgressButtonModule, SplitButtonAllModule, SplitButtonComponent, SplitButtonItemDirective, SplitButtonItemsDirective, SplitButtonModule };
