import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { Observable, catchError, map, of, tap, throwError } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';

@Injectable({
  providedIn: 'root'
})
export class InitialsService {
  private apiEndpoint = environment.staffInitialsApi.endpoint
    ? `${environment.staffInitialsApi.endpoint}/api/users`
    : null;

  private initials: { [key: string]: string } = {};
  private initials$: { [key: string]: Observable<string> } = {};

  constructor(private http: HttpClient, private logger: NGXLogger, private errorDialog: MatDialog) {}

  public getInitials$(email: string): Observable<string> {
    if (this.initials[email]) {
      return of(this.initials[email]);
    }

    if (!this.initials$[email]) {
      this.initials$[email] = this.apiEndpoint
        ? this.http
            .get(`${this.apiEndpoint}/${email}/initials`, {
              responseType: 'text'
            })
            .pipe(
              map((initials) => initials || this.extractFromEmail(email)),
              tap((initials) => (this.initials[email] = initials)),
              catchError((error: any) => {
                this.logger.error(error);
                showErrorDialog(this.errorDialog, error);
                return throwError(() => new Error(error));
              })
            )
        : of(this.extractFromEmail(email));
    }

    return this.initials$[email];
  }

  private extractFromEmail(email: string): string {
    return email
      .split('@')[0]
      .split('.')
      .map((w) => w[0])
      .join('')
      .toUpperCase();
  }
}
function showErrorDialog(errorDialog: MatDialog, error: any) {
  throw new Error('Function not implemented.');
}
