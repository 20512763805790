<div *ngIf="position">
  <div class="header">
    <h1>{{ position.jobTitle }}</h1>
  </div>
  <div class="card">
    <div class="card-body">
      <h2>Job Details</h2>
      <div class="row mb-4">
        <div class="col-sm-4"><strong>Job Title:</strong> {{ position.jobTitle }}</div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-12">
          <strong>Description:</strong>
          <p [innerHTML]="position.description"></p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-12">
          <strong>Requirements:</strong>
          <p [innerHTML]="position.requirements"></p>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-4"><strong>Location:</strong> {{ position.location }}</div>
        <div class="col-sm-4"><strong>Job Type:</strong> {{ position.jobType }}</div>
        <div class="col-sm-4"><strong>Team:</strong> {{ position.team }}</div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-4"><strong>CTGoodJobs Position Reference:</strong> {{ position.ctgoodjobsPositionRef }}</div>
        <div class="col-sm-4"><strong>JobsDB Position Reference:</strong> {{ position.jobsDBPositionRef }}</div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-4"><strong>Expected Starting Date:</strong> {{ position.startDate | date }}</div>
        <div class="col-sm-4"><strong>Application Deadline:</strong> {{ position.deadline | date }}</div>
      </div>
      <div *ngIf="position.questions.length > 0">
        <div class="horizontal-line bg-primary mb-4"></div>
        <h2>Questions</h2>
        <div class="questions-container">
          <div *ngFor="let question of position.questions; let i = index" class="question-item">
            <div class="form-group">
              <strong>Question {{ i + 1 }}:</strong>
              <p [innerHTML]="answer.question"></p>
            </div>
          </div>
        </div>
      </div>
      <div class="horizontal-line bg-primary mb-4"></div>
      <h2>Interview Process</h2>
      <div class="row mb-4">
        <div class="col-sm-4"><strong>Recruiting Mode:</strong> {{ position.interviewProcess.recruitingMode }}</div>
      </div>
      <div class="rounds-container">
        <div *ngFor="let round of position.interviewProcess.rounds; let i = index" class="round-item">
          <div class="form-group">
            <strong>Round {{ i + 1 }}:</strong>
            <div class="info-card">
              <div class="font">Interviewers:</div>
              <ejs-listview [dataSource]="round.interviewers" [fields]="{ text: 'name' }">
                <ng-template #template let-data>
                  <div>{{ data.displayName }}</div>
                </ng-template>
              </ejs-listview>
            </div>
          </div>
        </div>
      </div>
      <div class="horizontal-line bg-primary mb-4"></div>
      <h2>Compensation</h2>
      <div class="row mb-4">
        <div class="col-sm-4"><strong>Currency:</strong> {{ position.salaryCurrency }}</div>
        <div class="col-sm-4"><strong>Minimum Salary:</strong> {{ position.salaryMin }}</div>
        <div class="col-sm-4"><strong>Maximum Salary:</strong> {{ position.salaryMax }}</div>
      </div>
      <div class="row mb-4">
        <div class="col-sm-12"><strong>Disclose Salary:</strong> {{ position.discloseSalary ? 'Yes' : 'No' }}</div>
      </div>
    </div>
  </div>
</div>
