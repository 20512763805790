<div class="card-layout">
  <div class="e-card">
    <div class="e-card-content">
      <form class="form-horizontal" [formGroup]="positionForm" (ngSubmit)="onSubmit()">
        <!-- Job Details Section -->
        <div class="section">
          <div class="row mb-5">
            <h2>Job Details</h2>
          </div>

          <!-- Job Title Row -->
          <div class="row mb-4">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="jobTitle" class="control-label required">Job Title</label>
                <input type="text" class="e-textbox form-control" id="jobTitle" formControlName="jobTitle" />
              </div>
            </div>
          </div>

          <!-- Description Row -->
          <div class="row mb-4">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="description" class="control-label required">Description</label>
                <ejs-richtexteditor
                  id="iframeRTE"
                  [toolbarSettings]="tools"
                  [iframeSettings]="iframe"
                  [height]="height"
                  formControlName="description"
                ></ejs-richtexteditor>
              </div>
            </div>
          </div>

          <!-- Requirements Row -->
          <div class="row mb-4">
            <div class="col-sm-12">
              <div class="form-group">
                <label for="requirements" class="control-label required">Requirements</label>
                <ejs-richtexteditor
                  id="iframeRTE"
                  [toolbarSettings]="tools"
                  [iframeSettings]="iframe"
                  [height]="height"
                  formControlName="requirements"
                ></ejs-richtexteditor>
              </div>
            </div>
          </div>

          <!-- Location, Job Type, Team Row -->
          <div class="row mb-4">
            <!-- Location Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="location" class="control-label required">Location</label>
                <div class="select-container">
                  <select class="form-control" id="location" formControlName="location">
                    <option *ngFor="let location of locations" [value]="location[0]">{{ location[1] }}</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Job Type Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="jobType" class="control-label required">Job Type</label>
                <div class="select-container">
                  <select class="form-control" id="jobType" formControlName="jobType">
                    <option *ngFor="let jobType of jobTypes" [value]="jobType[0]">{{ jobType[1] }}</option>
                  </select>
                </div>
              </div>
            </div>

            <!-- Team Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="team" class="control-label required">Team</label>
                <div class="select-container">
                  <select class="form-control" id="team" formControlName="team">
                    <option *ngFor="let team of teams" [value]="team[0]">{{ team[1] }}</option>
                  </select>
                </div>
              </div>
            </div>
          </div>

          <!-- Second Row -->
          <div class="row mb-4">
            <!-- CTGoodJobs Position Reference Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="ctgoodjobsPositionRef" class="control-label required">CTGoodJobs Position Reference</label>
                <input
                  type="text"
                  class="form-control"
                  id="ctgoodjobsPositionRef"
                  formControlName="ctgoodjobsPositionRef"
                />
              </div>
            </div>

            <!-- JobsDB Position Reference Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="jobsDBPositionRef" class="control-label required">JobsDB Position Reference</label>
                <input type="text" class="form-control" id="jobsDBPositionRef" formControlName="jobsDBPositionRef" />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <ejs-checkbox label="HR Pre Screening" [formControlName]="'hrPrescreening'"></ejs-checkbox>
              </div>
            </div>
          </div>

          <!-- Expected Starting Date and Application Deadline Rows -->
          <div class="row mb-4">
            <!-- Expected Starting Date Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="startDate" class="control-label required">Expected Starting Date</label>
                <div class="text-center">
                  <ejs-checkbox label="As Soon as Possible" [formControlName]="'startDateAsap'"></ejs-checkbox>
                </div>
                <ejs-datepicker
                  formControlName="startDate"
                  placeholder="Start Date"
                  [enabled]="!positionForm.get('startDateAsap').value"
                ></ejs-datepicker>
              </div>
            </div>

            <!-- Application Deadline Column -->
            <div class="col-sm-4">
              <div class="form-group">
                <label for="deadline" class="control-label required">Deadline</label>
                <div class="text-center">
                  <ejs-checkbox label="On Going Basis" [formControlName]="'deadlineOngoing'"></ejs-checkbox>
                </div>
                <ejs-datepicker
                  formControlName="deadline"
                  placeholder="Enter date"
                  [enabled]="!positionForm.get('deadlineOngoing').value"
                ></ejs-datepicker>
              </div>
            </div>
          </div>
        </div>

        <div class="horizontal-line bg-primary mb-4"></div>

        <!-- Questions Section -->
        <div class="section">
          <div class="row mb-5">
            <h2>Questions</h2>
            <div formArrayName="questions" class="questions-container">
              <div>
                <div
                  *ngFor="let question of questions.controls; let i = index"
                  [formGroupName]="i"
                  class="question-item"
                >
                  <div class="form-group">
                    <app-question
                      [questionForm]="question"
                      (removeQuestionClicked)="removeQuestion(i)"
                      style="width: 100%"
                      [questionNumber]="i + 1"
                    ></app-question>
                  </div>
                </div>
              </div>
              <button class="add-btn" type="button" (click)="addQuestion()">Add Question</button>
            </div>
          </div>
        </div>

        <div class="horizontal-line bg-primary mb-4"></div>

        <!-- Interview Process Section -->
        <div class="section">
          <div class="row mb-5">
            <h2>Interview Process</h2>
          </div>
          <div class="row mb-4" formGroupName="interviewProcess">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="recruitingMode" class="control-label required">Recruiting Mode</label>
                <div class="select-container">
                  <select
                    class="form-control"
                    id="recruitingMode"
                    formControlName="recruitingMode"
                    (change)="toggleMinYesCount($event.target.value)"
                  >
                    <option *ngFor="let recruitingMode of recruitingModes" [value]="recruitingMode[0]">
                      {{ recruitingMode[1] }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-sm-4" *ngIf="showMinYesCount">
              <div class="form-group">
                <label for="recruitingMinYesCount" class="control-label required">Minimum Number of "Yes"</label>
                <input
                  type="number"
                  class="e-textbox form-control"
                  id="recruitingMinYesCount"
                  formControlName="recruitingMinYesCount"
                />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label for="screeningMode" class="control-label required">Screening Mode</label>
                <div class="select-container">
                  <select
                    class="form-control"
                    id="screeningMode"
                    formControlName="screeningMode"
                    (change)="toggleScreeningMinYesCount($event.target.value)"
                  >
                    <option *ngFor="let recruitingMode of recruitingModes" [value]="recruitingMode[0]">
                      {{ recruitingMode[1] }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-sm-4" *ngIf="showScreeningMinYesCount">
              <div class="form-group">
                <label for="screeningMinYesCount" class="control-label required">Screening Minimum Number of "Yes"</label>
                <input
                  type="number"
                  class="e-textbox form-control"
                  id="screeningMinYesCount"
                  formControlName="screeningMinYesCount"
                />
              </div>
            </div>

            <div class="row mb-2">
              <div formArrayName="rounds" class="rounds-container">
                <div>
                  <div *ngFor="let round of rounds.controls; let i = index" [formGroupName]="i" class="round-item">
                    <div class="form-group">
                      <app-round
                        [roundForm]="round"
                        (removeRoundClicked)="removeRound(i)"
                        style="width: 100%"
                        [roundNumber]="i + 1"
                      ></app-round>
                    </div>
                  </div>
                </div>
                <button class="add-btn" type="button" (click)="addRound()">Add Round</button>
              </div>
            </div>
          </div>
        </div>

        <div class="horizontal-line bg-primary mb-4"></div>

        <!-- Compensation Section -->
        <div class="section">
          <div class="row mb-5">
            <h2>Compensation</h2>
          </div>
          <div class="row mb-4">
            <div class="col-sm-4">
              <div class="form-group">
                <label for="salaryCurrency" class="control-label required">Currency</label>
                <input type="text" class="form-control" id="salaryCurrency" formControlName="salaryCurrency" />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label for="salaryMin" class="control-label required">Minimum Salary</label>
                <input type="number" class="form-control" id="salaryMin" formControlName="salaryMin" />
              </div>
            </div>

            <div class="col-sm-4">
              <div class="form-group">
                <label for="salaryMax" class="control-label required">Maximum Salary</label>
                <input type="number" class="form-control" id="salaryMax" formControlName="salaryMax" />
              </div>
            </div>
          </div>

          <div class="row mb-4">
            <div class="col-sm-12">
              <ejs-checkbox label="Disclose Salary" [formControlName]="'discloseSalary'"></ejs-checkbox>
            </div>
          </div>
        </div>

        <!-- Submit Button -->
        <div class="row">
          <button ejs-progressbutton class="btn-primary" [disabled]="!positionForm.valid">
            {{ isEditMode ? 'Save' : 'Submit' }}
          </button>
        </div>

        <div *ngIf="!positionForm.valid" class="validation-errors">
          <p>Please correct the following errors:</p>
          <ul>
            <li *ngIf="positionForm.controls['location'].errors?.required">Location is required</li>
            <li *ngIf="positionForm.controls['team'].errors?.required">Team is required</li>
            <li *ngIf="positionForm.controls['description'].errors?.required">Description is required</li>
            <li *ngIf="positionForm.controls['jobTitle'].errors?.required">Job Title is required</li>
            <li *ngIf="positionForm.controls['jobType'].errors?.required">Job Type is required</li>
            <li *ngIf="positionForm.controls['hrPrescreening'].errors?.required">HR Prescreening is required</li>
            <li *ngIf="positionForm.controls['salaryCurrency'].errors?.required">Salary Currency is required</li>
            <li *ngIf="positionForm.controls['salaryMin'].errors?.required">Minimum Salary is required</li>
            <li *ngIf="positionForm.controls['salaryMax'].errors?.required">Maximum Salary is required</li>
            <li *ngIf="positionForm.errors?.invalidSalaryRange">Minimum Salary must be less than Maximum Salary</li>
            <li *ngIf="positionForm.get('interviewProcess').errors?.invalidRecruitingMinYesCount">
              Recruiting Mode is Minimum Number of Yes, but Minimum Yes Count is missing or invalid
            </li>
            <li *ngIf="positionForm.get('interviewProcess').errors?.minYesCountExceedsInterviewers">
              Minimum Yes Count exceeds the number of interviewers in one or more rounds
            </li>
            <li *ngIf="positionForm.get('questions').errors?.invalidQuestionLength">
              Each question must be at least 20 characters long
            </li>
            <li *ngIf="hasRoundWithoutInterviewers">At least one interviewer is required for each round</li>
          </ul>
        </div>
      </form>
    </div>
  </div>
</div>
