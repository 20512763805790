import { Component } from '@angular/core';
import { CareersAuthService } from '../../auth/careers-auth.service';
import { ROLE_STAFF } from '../shared/constants';

@Component({
  selector: 'of-can-view-applications',
  standalone: true,
  imports: [],
  template: `
    @if (canView) {
      <ng-content></ng-content>
    }
  `
})
export class CanViewApplicationsComponent {
  public canView: boolean | null = null;

  constructor(private authService: CareersAuthService) {}

  ngOnInit(): void {
    this.canView = this.authService.hasOneOfRoles([ROLE_STAFF]) || this.authService.isHR();
  }
}
