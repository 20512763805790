import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, catchError, of, tap, throwError } from 'rxjs';
import { showErrorDialog } from '../shared/utils';
import { GraphUser, AzureB2CUser, UserDto } from '../model/user.model';
@Injectable({
  providedIn: 'root'
})
export class UsersService {
  private apiEndpoint = `${environment.apiEndpoint}/api/users`;

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
    private storage: LocalStorageService
  ) { }

  public getUserByEmail$(email: string): Observable<AzureB2CUser> {
    const user = this.storage.get<AzureB2CUser>(`user_${email}`);

    return user
      ? of(user)
      : this.http.get<AzureB2CUser>(`${this.apiEndpoint}/${email}`).pipe(
        tap((res) => this.storage.set(`user_${email}`, res)),
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public me$(): Observable<GraphUser> {
    const me = this.storage.get<GraphUser>(`me`);    
    return me
    ? of(me)
    : this.http.get<GraphUser>(`${this.apiEndpoint}/me`).pipe(
      tap((res) => this.storage.set(`me`, res)),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getUsersByDepartment$(department: string): Observable<UserDto[]> {

    return this.http.get<UserDto[]>(`${this.apiEndpoint}/department/${department}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getInterviewers$(): Observable<GraphUser[]> {

    return this.http.get<GraphUser[]>(`${this.apiEndpoint}/interviewers`).pipe(
        catchError((error: any) => {
          this.logger.error(error);
          showErrorDialog(this.errorDialog, error);
          return throwError(() => new Error(error));
        })
      );
  }

  public createUser$(userDto: AzureB2CUser): Observable<AzureB2CUser> {
    return this.http.post<AzureB2CUser>(`${this.apiEndpoint}/graph-user`, userDto).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
