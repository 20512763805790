import { Component, Input } from '@angular/core';
import { NgStyle } from '@angular/common';
import { randomCigpColor } from './utils';
import { UserDto } from '../model/user.model';

@Component({
  selector: 'of-avatar-card',
  standalone: true,
  imports: [NgStyle],
  template: `
    @if (user) {
      <div class="d-flex gap-2 align-items-center">
        @if (user.profilePicture) {
          <img [src]="user.profilePicture" width="30" height="30" class="e-avatar e-avatar-circle" [alt]="user.name" [title]="user.name" />
        } @else {
          <div
            class="e-avatar e-avatar-circle text-white d-flex justify-content-center align-items-center"
            [ngStyle]="{ 'background-color': backgroundColor }"
            [title]="user.name"
          >
            {{ user.initials }}
          </div>
        }

        @if (showName) {
          <div>{{ user.name }}</div>
        }
      </div>
    }
  `,
  styles: `
  img { height: 36px; width: 36px; }
  div.e-avatar { height: 36px; width: 36px; cursor: default; }
  `
})
export class AvatarCardComponent {
  public backgroundColor = randomCigpColor();

  @Input()
  public user: UserDto | null = null;

  @Input()
  public showName = false;
}
