import { Injectable } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl, FormArray } from '@angular/forms';
import { CreatePositionFormConfig } from '../model/form-configs/create-position-form-config';
import { QuestionFormConfig } from '../model/form-configs/question-form-config';
import { InterviewerFormConfig } from '../model/form-configs/interviewer-form-config';
import { RoundFormConfig } from '../model/form-configs/round-form-config';
import { PositionDto } from '../model/position-model';
import { InterviewProcessFormConfig } from '../model/form-configs/interview-process-form-config';
import { RoundDto } from '../model/round-model';
import { InterviewerDto } from '../model/interviewer';
import { validateInterviewersArray, validateMinYesCountWithInterviewers, validateQuestionsArray, validateRecruitingMinYesCount, validateSalaryRange } from '../validators/position-validator';

@Injectable({
  providedIn: 'root'
})
export class PositionFormService {
  constructor(private fb: FormBuilder) {}

  createForm(): FormGroup {
    return this.fb.group<CreatePositionFormConfig>({
      location: new FormControl(null, Validators.required),
      team: new FormControl(null, Validators.required),
      ctgoodjobsPositionRef: new FormControl(null),
      deadline: new FormControl(null),
      description: new FormControl(null, Validators.required),
      discloseSalary: new FormControl(false),
      jobsDBPositionRef: new FormControl(null),
      startDateAsap: new FormControl(false),
      deadlineOngoing: new FormControl(false),
      jobTitle: new FormControl(null, Validators.required),
      jobType: new FormControl(null, Validators.required),
      hrPrescreening: new FormControl(false, Validators.required),
      questions: this.fb.array([
        this.fb.group<QuestionFormConfig>({
          question: new FormControl(null),
          isMandatory: new FormControl(false)
        })
      ], { validators: validateQuestionsArray() }),
      requirements: new FormControl(null, Validators.required),
      salaryCurrency: new FormControl(null, Validators.required),
      salaryMax: new FormControl(null, Validators.required),
      salaryMin: new FormControl(null, Validators.required),
      startDate: new FormControl(null),
      interviewProcess: this.fb.group<InterviewProcessFormConfig>({
        recruitingMode: new FormControl(null, Validators.required),
        screeningMode: new FormControl(null, Validators.required),
        recruitingMinYesCount: new FormControl(null),
        screeningMinYesCount: new FormControl(null),
        rounds: this.fb.array([
          this.fb.group<RoundFormConfig>({
            roundNumber: new FormControl(null, Validators.required),
            interviewers: this.fb.array<FormGroup<InterviewerFormConfig>>([], Validators.required),
          }, { validators: validateInterviewersArray() })
        ])
      }, { validators: [validateRecruitingMinYesCount(), validateMinYesCountWithInterviewers()] }),
    }, { validators: validateSalaryRange() });
  }

  initializeForm(form: FormGroup, positionData: PositionDto) {      
    let startDate = positionData.startDate.toString() == "0001-01-01T00:00:00+00:00" ? null : new Date(positionData.startDate);
    let deadlineDate = positionData.deadline.toString() == "0001-01-01T00:00:00+00:00" ? null : new Date(positionData.deadline);
     
    form.patchValue({
      location: positionData.location,
      team: positionData.team,
      ctgoodjobsPositionRef: positionData.ctgoodjobsPositionRef,
      deadline: deadlineDate,
      description: positionData.description,
      discloseSalary: positionData.discloseSalary,
      jobsDBPositionRef: positionData.jobsDBPositionRef,
      startDateAsap: startDate == null,
      deadlineOngoing: deadlineDate == null,
      jobTitle: positionData.jobTitle,
      jobType: positionData.jobType,
      hrPrescreening: positionData.hrPrescreening,
      requirements: positionData.requirements,
      salaryCurrency: positionData.salaryCurrency,
      salaryMax: positionData.salaryMax,
      salaryMin: positionData.salaryMin,
      startDate,
      interviewProcess: {
        recruitingMode: positionData.interviewProcess.recruitingMode,
        recruitingMinYesCount: positionData.interviewProcess.recruitingMinYesCount
      }
    });
  
    const questionsFormArray = form.get('questions') as FormArray;
    questionsFormArray.clear();
    positionData.questions.forEach((question: any) => {
      questionsFormArray.push(this.fb.group<QuestionFormConfig>({
        question: new FormControl(question.question, Validators.required),
        isMandatory: new FormControl(question.isMandatory)
      }));
    });
  
    const roundsFormArray = (form.get('interviewProcess') as FormGroup).get('rounds') as FormArray;
    roundsFormArray.clear();
    
    positionData.interviewProcess.rounds.forEach((round: RoundDto) => {
      const interviewersFormArray = this.fb.array<FormGroup<InterviewerFormConfig>>([]);
      round.interviewers.forEach((interviewer: InterviewerDto) => {
        interviewersFormArray.push(this.fb.group<InterviewerFormConfig>({
          email: new FormControl(interviewer.email),
          canViewSalary: new FormControl(interviewer.canViewSalary),
          displayName: new FormControl(interviewer.displayName)
        }));
      });
      
      roundsFormArray.push(this.fb.group<RoundFormConfig>({
        roundNumber: new FormControl(round.roundNumber),
        interviewers: interviewersFormArray
      }, { validators: validateInterviewersArray() }));
    });
  }
  

  addQuestion(form: FormGroup) {
    const questions = form.get('questions') as FormArray;
    questions.push(this.fb.group({
      question: '',
      isMandatory: false
    }));
  }

  reset(form: FormGroup){
    form.reset();
  }

  addRound(form: FormGroup) {
    const rounds = form.get('interviewProcess').get('rounds') as FormArray;
    rounds.push(this.fb.group({
      roundNumber: '',
      interviewers: this.fb.array([])
    }, { validators: validateInterviewersArray() }));
  }
}
