<div [formGroup]="aggregateInterface.answer" class="question-answer-form">
  <label
    class="control-label"
    [innerHTML]="aggregateInterface.question.question"
  ></label>
  <ejs-richtexteditor
    [toolbarSettings]="tools"
    [height]="height"
    formControlName="answer"
    class="richtext-editor"
  >
  </ejs-richtexteditor>
  <div
    *ngIf="
      aggregateInterface.answer.get('answer')?.invalid &&
      aggregateInterface.answer.get('answer')?.touched
    "
    class="error"
  >
    Answer is required.
  </div>
</div>
