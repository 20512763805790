<div *ngIf="loading; else content">
  <app-spinner></app-spinner>
</div>
<ng-template #content>
  <div *ngIf="position">
    <div *ngIf="isHR; else readOnlyContent">
      <div class="d-flex justify-content-between align-items-center">
        <h1>{{ position.jobTitle }}</h1>
        <div class="button-container">
          <button class="btn btn-success" (click)="openPosition()" [hidden]="!position.closed">Open</button>
          <button class="btn btn-danger" (click)="closePosition()" [hidden]="position.closed">Close</button>
          <button class="btn btn-success" (click)="publishPosition()" [hidden]="position.published || position.closed">
            Publish
          </button>
          <button class="btn btn-danger" (click)="unpublishPosition()" [hidden]="!position.published">
            Unpublish
          </button>
          <button
            class="action-button btn btn-danger"
            (click)="openDialog(deleteDialog)"
            [disabled]="!canClickDeletePosition()"
          >
            Delete
          </button>
        </div>
      </div>
      <div class="card">
        <app-position-form
          [isEditMode]="true"
          (formSubmit)="onSubmit($event)"
          [positionData]="position"
        ></app-position-form>
      </div>

      <ejs-dialog #deleteDialog header="Delete Position" width="800" [isModal]="true" [visible]="false">
        <ng-template #content>
          <div class="dialog-content">
            <div class="buttons">
              <button class="action-button btn btn-success" (click)="confirmAction(deleteDialog, 'delete')">
                Confirm
              </button>
              <button class="action-button btn btn-danger" (click)="closeDialog(deleteDialog)">Cancel</button>
            </div>
          </div>
        </ng-template>
      </ejs-dialog>
    </div>
    <ng-template #readOnlyContent>
      <app-read-position-details [position]="position"></app-read-position-details>
    </ng-template>
  </div>
</ng-template>
