<div class="card-layout">
  <div class="e-card">
    <div class="e-card-content">
      <form class="form-horizontal" [formGroup]="applicationForm" (ngSubmit)="onSubmit()">
        <!-- First Section - CV Upload -->
        <div class="section">
          <div class="row mb-5">
            <h2>Upload CV</h2>
          </div>
          <div class="row mb-4">
            <div>
              <!-- Upload CV Button -->
              <app-upload-cv></app-upload-cv>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
