import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormBuilder, ReactiveFormsModule, FormsModule, FormControl } from '@angular/forms';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import {
  HtmlEditorService,
  ImageService,
  LinkService,
  RichTextEditorAllModule,
  ToolbarService
} from '@syncfusion/ej2-angular-richtexteditor';
import { ApplicationDto } from '../../model/application-model';
import { ApplicationFormConfig } from '../../model/form-configs/application-form-config';
import { UploadCVComponent } from './cv/upload-cv.component';

@Component({
  selector: 'upload-application',
  templateUrl: './upload-application.component.html',
  styleUrl: './upload-application.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerModule,
    CheckBoxModule,
    FormsModule,
    RichTextEditorAllModule,
    UploadCVComponent
  ],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class UploadApplicationComponent {
  applicationForm: FormGroup = new FormGroup({});

  constructor(private fb: FormBuilder) {
    this.applicationForm = this.fb.group<ApplicationFormConfig>({
      positionId: new FormControl(null),
      candidateId: new FormControl(null),
      source: new FormControl(null),
      answers: new FormControl(null),
      immigration: new FormControl(null),
      path: new FormControl(null),
      cvUrl: new FormControl(null),
      pathAdditionalDoc1: new FormControl(null),
      pathAdditionalDoc2: new FormControl(null),
      pathCoverLetter: new FormControl(null),
      privacy: new FormControl(null),
      referralSource: new FormControl(null)
    });
  }

  ngOnInit() {}

  onSubmit() {
    if (this.applicationForm.valid) {
    }
  }

  reset() {
    this.applicationForm.reset();
  }
}
