import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { InterviewDto } from '../../model/interview-model';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { InterviewStatus } from '../../model/enums/interview-status';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ApplicationDto } from '../../model/application-model';

@Component({
  selector: 'app-interview-card',
  templateUrl: './interview-card.component.html',
  styleUrls: ['./interview-card.component.css'],
  imports: [ListViewModule, DialogModule, TextBoxModule, CommonModule, FormsModule],
  standalone: true
})
export class InterviewCardComponent implements OnInit{
  @Input() interview: InterviewDto;
  @Input() application: ApplicationDto;
  @Output() applicationUpdated: EventEmitter<ApplicationDto> = new EventEmitter<ApplicationDto>();

  constructor() { }
  ngOnInit(): void {}

  hasInterviewNotes(): boolean {
    if (this.interview.postInterviews) {

      return this.interview.postInterviews.some(postInterview => postInterview.interviewNotes && postInterview.interviewNotes.length > 0);
    } else { return false; }
  }

  get interviewersWithNotes() {
    return this.interview.postInterviews.filter(postInterview => postInterview.interviewNotes && postInterview.interviewNotes.length > 0);
  }


  getBackgroundColor(): string {
    switch (this.interview.status) {
      case InterviewStatus.Completed:
        return 'bg-success border-success';
      case InterviewStatus.Created:
        return 'bg-warning border-info';
      case InterviewStatus.Scheduled:
        return 'bg-info border-info';
      case InterviewStatus.Canceled:
        return 'bg-danger border-danger';
      default:
        return 'bg-light border-light';
    }
  }
}
