import { Component, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { Location } from '../../model/enums/location';
import { RecruitingMode } from '../../model/enums/recruiting-mode';
import { Team } from '../../model/enums/team';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { JobType } from '../../model/enums/job-type';
import { Observable } from 'rxjs';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from '../../shared/sortable.directive';

import {
  Column,
  FilterService,
  GridModule,
  GroupService,
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  ResizeService,
  SelectionSettingsModel,
  SortService
} from '@syncfusion/ej2-angular-grids';
import { style } from '@angular/animations';
import { ApplicationDto } from '../../model/application-model';
import { ApplicationsService } from '../../services/applications.service';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { PositionDto } from '../../model/position-model';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { CareersAuthService } from '../../../auth/careers-auth.service';
import { ROLE_HR_DUBAI, ROLE_HR_GENEVA, ROLE_HR_HK } from '../../shared/constants';
import { getJobTypeValue, getLocationValue, getStatusValue, getTeamValue } from '../../shared/utils';
import { SpinnerComponent } from '../../shared/spinner.component';

@Component({
  selector: 'list-application',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.scss'],
  standalone: true,
  imports: [
    MatIconModule,
    MatListModule,
    MatDividerModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    AsyncPipe,
    NgbPaginationModule,
    GridModule,
    DropDownListModule,
    CheckBoxModule,
    SpinnerComponent
  ],
  providers: [PageService, SortService, FilterService, ResizeService]
})
export class ApplicationListComponent implements OnInit {
  applications?: ApplicationDto[];
  filteredApplications?: ApplicationDto[];
  public pageSettings?: PageSettingsModel;
  selectedPosition: string | null = null;
  selectedLocation: string | null = null;
  selectedTeam: string | null = null;
  selectedJobType: string | null = null;
  teams = Object.values(Team);
  locations = Object.values(Location);
  jobTypes = Object.values(JobType);
  positions: PositionDto[];
  public fields: Object = { text: 'jobTitle', value: 'id' };
  public loading = true;

  ngOnInit(): void {
    if (this.authService.isHR()) {
      this.loadApplicationsBasedOnRole();
    } else {
      this.loadUserApplications();
    }
  }

  private loadApplicationsBasedOnRole(): void {
    const roleServiceMap = {
      [ROLE_HR_HK]: this.service.getAllHKApplications$.bind(this.service),
      [ROLE_HR_DUBAI]: this.service.getAllDubaiApplications$.bind(this.service),
      [ROLE_HR_GENEVA]: this.service.getAllGenevaApplications$.bind(this.service)
    };

    for (const role in roleServiceMap) {

      if (this.authService.hasRole(role)) {
        roleServiceMap[role]().subscribe((applications: ApplicationDto[]) => this.handleApplications(applications));
      }
    }
    if (this.applications) {
      this.applications.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }
  }

  private loadUserApplications(): void {
    this.service.getUserApplications$().subscribe((applications) => this.handleApplications(applications));
  }

  private handleApplications(applications: ApplicationDto[]): void {
    this.applications = this.applications ? this.applications.concat(applications) : applications;
    if (applications != null) {
      this.filteredApplications = this.applications.filter((app) => app?.position?.closed === false);
      this.extractUniquePositions();
      this.loading = false;
    }
  }

  extractUniquePositions(): void {
    const positionMap = new Map<number, PositionDto>();
    this.filteredApplications.forEach((application) => {
      positionMap.set(Number.parseInt(application.position.id), application.position);
    });
    this.positions = Array.from(positionMap.values());
  }

  constructor(
    private router: Router,
    public service: ApplicationsService,
    public authService: CareersAuthService,
  ) {
    this.pageSettings = { pageSize: 40 };
  }

  navigateToApplicationDetail(application: ApplicationDto) {
    this.router.navigate(['/applications', 'details', application.id]);
  }

  getTeamValue(enumKey: string) {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string) {
    return getLocationValue(enumKey);
  }

  getStatusValue(enumKey: string) {
    return getStatusValue(enumKey);
  }

  applyFilter(value: string | boolean, filterType: string): void {
    // Update the filteredApplications array with the initial applications array
    if (this.applications?.length > 0) {
      this.filteredApplications = this.applications.slice();

      // Apply each filter based on the selected criteria
      if (value !== null && value !== '' && this.applications.length > 0) {
        switch (filterType) {
          case 'position':
            this.filteredApplications = this.filteredApplications.filter((app) => app.position.id === value);
            break;
          case 'location':
            this.filteredApplications = this.filteredApplications.filter((app) => getLocationValue(app.position.location) === value);
            break;
          case 'team':
            this.filteredApplications = this.filteredApplications.filter((app) => getTeamValue(app.position.team) === value);
            break;
          case 'jobType':
            this.filteredApplications = this.filteredApplications.filter((app) => getJobTypeValue(app.position.jobType) === value);
            break;
          case 'closed':
            this.filteredApplications = this.filteredApplications.filter((app) => app.position.closed === value);
            break;
          default:
            break;
        }
      }

      // Reapply all selected filters together
      this.filteredApplications = this.filteredApplications.filter(
        (app) =>
          (!this.selectedPosition || app.position.jobTitle === this.selectedPosition) &&
          (!this.selectedLocation || app.position.location === this.selectedLocation) &&
          (!this.selectedTeam || app.position.team === this.selectedTeam) &&
          (!this.selectedJobType || app.position.jobType === this.selectedJobType)
      );
    }
  }
}

