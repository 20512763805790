import { Component, OnInit } from '@angular/core';
import { Router, RouterOutlet } from '@angular/router';
import { Observable, Subject, filter, map, of, shareReplay, takeUntil } from 'rxjs';
import { environment } from '../environments/environment';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CareersAuthService } from '../auth/careers-auth.service';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { InteractionStatus } from '@azure/msal-browser';
import { HeaderComponent } from './header/header.component';
import { InitialsService } from './services/initials.service';
import { HealthService } from './services/health.service';
import { DecimalPipe } from '@angular/common';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [HeaderComponent, RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit {
  private readonly destroying$ = new Subject<void>();

  public isIframe: boolean;
  public production = environment.env === 'production';
  private wasUnavailable = false;

  isHandset$: Observable<boolean> = this.breakpointObserver.observe(Breakpoints.Handset).pipe(
    map((result) => result.matches),
    shareReplay()
  );

  constructor(
    private authService: MsalService,
    private breakpointObserver: BreakpointObserver,
    public careersAuthService: CareersAuthService,
    private msalBroadcastService: MsalBroadcastService,
    private healthService: HealthService,
    private router: Router,
    private initialsService: InitialsService
  ) {
    //  This is to avoid reload during acquireTokenSilent() because of hidden iframe
    this.isIframe = window !== window.parent && !window.opener;
  }

  public login(): void {
    this.careersAuthService.login$().subscribe();
  }

  public logout(): void {
    this.careersAuthService.logout$().subscribe();
  }

  get menuLabel$(): Observable<string> {
    return this.careersAuthService.loggedIn && this.careersAuthService.account
      ? this.initialsService.getInitials$(this.careersAuthService.account.username)
      : of('LOGIN');
  }

  public get loggedIn(): boolean {
    return this.careersAuthService.loggedIn;
  }

  ngOnInit(): void {
    this.authService.handleRedirectObservable().subscribe();

    this.careersAuthService.checkAndSetActiveAccount();

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this.destroying$)
      )
      .subscribe(() => {
        this.careersAuthService.checkAndSetActiveAccount();
      });

    /*this.healthService.isBackendAvailableInterval$().subscribe((isAvailable) => {
      if (!isAvailable) {
        this.wasUnavailable = true;
        //this.router.navigate(['/unavailable']);
      } else if (this.wasUnavailable) {
        this.wasUnavailable = false;
        this.router.navigate(['/']);
      }
    });*/
  }
}
