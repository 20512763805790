export enum Team {
  AccountingAndFinance = 'A&F',
  Administration = 'Administration',
  AssetManagement = 'AM',
  ChiefOfStaff = 'CoS',
  Compliance = 'Compliance',
  HR = 'HR',
  InvestmentBanking = 'IB',
  IT = 'IT',
  RelationshipManager = 'RM',
  ResearchWorkingGroup = 'RWG',
  TradingAndOperations = 'T&O',
  WealthManagement = 'WM'
}
