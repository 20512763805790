import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CanViewInterviewsComponent } from '../interview/can-view-interviews.component';
import { CanWriteInterviewsComponent } from '../interview/can-write-interviews.component';

@Component({
  selector: 'of-interviews-nav-menu',
  standalone: true,
  imports: [RouterModule, CanViewInterviewsComponent, CanWriteInterviewsComponent],
  template: `
    <of-can-view-interviews>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Interviews
        </a>
        <ul class="dropdown-menu">
        <of-can-write-interviews>
          <li>
            <a class="dropdown-item" [routerLink]="['/interviews/']"><i class="bi bi-list"></i>All interviews</a>
          </li>
          </of-can-write-interviews>
          <of-can-view-interviews>
            <li>
              <a class="dropdown-item" [routerLink]="['/interviews', 'upcoming']"><i class="bi bi-plus-lg"></i>My Upcoming Interviews</a>
            </li>
          </of-can-view-interviews>
        </ul>
      </li>
    </of-can-view-interviews>
  `
})
export class InterviewsNavMenuComponent { }
