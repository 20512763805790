import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService } from '@azure/msal-angular';
import { AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { combineLatest, filter, map, tap } from 'rxjs';
import { CareersAuthService } from '../../auth/careers-auth.service';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

@Component({
  selector: 'of-landing',
  standalone: true,
  imports: [MatProgressSpinnerModule],
  template: `
    @if (!authService.loggedIn) {
      <div class="d-flex justify-content-center align-items-center gap-4">
        <button class="btn btn-outline-primary" (click)="login()">PLEASE LOG IN</button>
      </div>
    }
  `
})
export class LandingComponent implements OnInit {
  public loadingSecurities = true;
  public loadingPortfolios = true;

  constructor(
    public authService: CareersAuthService,
    private router: Router,
    private msalBroadcastService: MsalBroadcastService
  ) {}

  public ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
      .pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS))
      .subscribe((result: EventMessage) => {
        const payload = result.payload as AuthenticationResult;
        this.authService.setActiveAccount(payload.account);
        this.router.navigate(['/home']);
      });

    this.msalBroadcastService.inProgress$
      .pipe(filter((status: InteractionStatus) => status === InteractionStatus.None))
      .subscribe(() => {
        if (this.authService.loggedIn) {
          this.router.navigate(['/home']);
        }
      });
  }

  public login(): void {
    this.authService.login$().subscribe();
  }
}
