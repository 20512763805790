import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { HtmlEditorService, ImageService, LinkService, RichTextEditorAllModule, RichTextEditorComponent, RichTextEditorModule, ToolbarService } from '@syncfusion/ej2-angular-richtexteditor';

@Component({
    selector: 'app-question',
    templateUrl: './question.component.html',
    styleUrls: ['./question.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RichTextEditorAllModule,
        CheckBoxModule,
    ],
    providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class QuestionComponent {
    @Input() questionForm: FormGroup;
    @Input() questionNumber: number;
    @Output() removeQuestionClicked = new EventEmitter<void>();

    @ViewChild('fromRTE')
    private rteEle: RichTextEditorComponent;
    public isMandatory: boolean = false;
    public tools: object = {
        items: ['Undo', 'Redo', '|',
            'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontSize', '|',
            'Formats', 'Alignments', '|',
            'CreateLink', 'Image', '|', 'SourceCode']
    };
    public iframe: object = { enable: true };
    public height: number = 150;

    ngOnInit(): void {}

    constructor(private fb: FormBuilder) { }

    removeQuestion() {
        this.removeQuestionClicked.emit();
    }

    rteCreated(): void {
        this.rteEle.element.focus();
    }

    onSubmit(): void {
        alert('Form submitted successfully');
    }
}
