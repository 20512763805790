import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PositionFormService } from '../../services/position-form.service';
import { Team } from '../../model/enums/team';
import { JobType } from '../../model/enums/job-type';
import { Location } from '../../model/enums/location';
import { RecruitingMode } from '../../model/enums/recruiting-mode';
import { ToolbarService, LinkService, ImageService, HtmlEditorService, RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CommonModule } from '@angular/common';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { DatePickerModule } from '@syncfusion/ej2-angular-calendars';
import { QuestionComponent } from '../create/question/question.component';
import { RoundComponent } from '../create/round/round.component';
import { PositionDto } from '../../model/position-model';
import { ProgressButtonModule } from '@syncfusion/ej2-angular-splitbuttons';

@Component({
  selector: 'app-position-form',
  templateUrl: './position-form.component.html',
  styleUrls: ['./position-form.component.css'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    DatePickerModule,
    CheckBoxModule,
    FormsModule,
    RoundComponent,
    QuestionComponent,
    RichTextEditorAllModule,
    ProgressButtonModule
  ],
  providers: [ToolbarService, LinkService, ImageService, HtmlEditorService]
})
export class PositionFormComponent implements OnInit {
  @Input() positionData: PositionDto;
  @Input() isEditMode: boolean = false;
  @Output() formSubmit = new EventEmitter<FormGroup>();
  public spinSettings = { position: 'Right', width: 20, template: '<div class="template"></div>' };

  positionForm: FormGroup;

  teams = Object.entries(Team);
  recruitingModes = Object.entries(RecruitingMode);
  locations = Object.entries(Location);
  jobTypes = Object.entries(JobType);

  showMinYesCount: boolean = false;
  showScreeningMinYesCount: boolean = false;
  public tools: object = {
    items: ['Undo', 'Redo', '|', 'Bold', 'Italic', 'Underline', 'StrikeThrough', 'FontSize', '|', 'Formats', 'Alignments', '|', 'CreateLink', 'Image', '|', 'SourceCode']
  };
  public iframe: object = { enable: true };
  public height: number = 200;

  constructor(private positionFormService: PositionFormService) {
    this.positionForm = this.positionFormService.createForm();
  }

  ngOnInit(): void {

    if (this.isEditMode && this.positionData) {
      this.positionFormService.initializeForm(this.positionForm, this.positionData);
    }
  }

  get questions() {
    return this.positionForm.get('questions') as FormArray;
  }

  get rounds() {
    return (this.positionForm.get('interviewProcess') as FormGroup).get('rounds') as FormArray;
  }

  addQuestion(): void {
    this.positionFormService.addQuestion(this.positionForm);
  }

  removeQuestion(index: number): void {
    this.questions.removeAt(index);
  }

  addRound(): void {
    this.positionFormService.addRound(this.positionForm);
  }

  removeRound(index: number): void {
    this.rounds.removeAt(index);
  }

  toggleMinYesCount(value: string): void {
    this.showMinYesCount = value === 'MinYes';
  }

  toggleScreeningMinYesCount(value: string): void {
    this.showScreeningMinYesCount = value === 'MinYes';
  }

  get hasRoundWithoutInterviewers(): boolean {
    const rounds = this.positionForm.get('interviewProcess').get('rounds') as FormArray;
    return rounds.controls.some(round => round.errors?.noInterviewers);
  }

  onSubmit(): void {
    if (this.positionForm.invalid) {
      return;
    }
    this.formSubmit.emit(this.positionForm);
  }
}
