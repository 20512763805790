import { __decorate } from 'tslib';
import * as i0 from '@angular/core';
import { forwardRef, Component, ChangeDetectionStrategy, NgModule, Directive, ContentChildren, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { setValue, FormBase, ComponentBase, ComponentMixins, ComplexBase, ArrayBase, Template } from '@syncfusion/ej2-angular-base';
import { TextBox, TextArea, NumericTextBox, MaskedTextBox, Slider, Uploader, ColorPicker, Signature, Rating, OtpInput, FormValidator } from '@syncfusion/ej2-inputs';
export * from '@syncfusion/ej2-inputs';
import { CommonModule } from '@angular/common';
const _c0 = ["template"];
const _c1 = ["ejs-colorpicker", ""];
const _c2 = ["ejs-signature", ""];
const _c3 = ["fullTemplate"];
const _c4 = ["emptyTemplate"];
const _c5 = ["tooltipTemplate"];
const _c6 = ["labelTemplate"];
const _c7 = ["ejs-rating", ""];
const _c8 = ["ejs-otpinput", ""];
var TextBoxComponent_1;
const inputs$9 = ['autocomplete', 'cssClass', 'enablePersistence', 'enableRtl', 'enabled', 'floatLabelType', 'htmlAttributes', 'locale', 'multiline', 'placeholder', 'readonly', 'showClearButton', 'type', 'value', 'width'];
const outputs$a = ['blur', 'change', 'created', 'destroyed', 'focus', 'input', 'valueChange'];
const twoWays$9 = ['value'];
/**
 * Represents the EJ2 Angular TextBox Component.
 * ```html
 * <ejs-textbox [value]='value'></ejs-textbox>
 * ```
 */
let TextBoxComponent = TextBoxComponent_1 = class TextBoxComponent extends TextBox {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$a);
    this.addTwoWay.call(this, twoWays$9);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
TextBoxComponent.ɵfac = function TextBoxComponent_Factory(t) {
  return new (t || TextBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
TextBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TextBoxComponent,
  selectors: [["ejs-textbox"]],
  inputs: {
    autocomplete: "autocomplete",
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    floatLabelType: "floatLabelType",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    multiline: "multiline",
    placeholder: "placeholder",
    readonly: "readonly",
    showClearButton: "showClearButton",
    type: "type",
    value: "value",
    width: "width"
  },
  outputs: {
    blur: "blur",
    change: "change",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    input: "input",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextBoxComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function TextBoxComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
TextBoxComponent = TextBoxComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], TextBoxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextBoxComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-textbox',
      inputs: inputs$9,
      outputs: outputs$a,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextBoxComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the TextBox component.
 */
class TextBoxModule {}
TextBoxModule.ɵfac = function TextBoxModule_Factory(t) {
  return new (t || TextBoxModule)();
};
TextBoxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TextBoxModule
});
TextBoxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TextBoxComponent],
      exports: [TextBoxComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the TextBox component with providers.
 */
class TextBoxAllModule {}
TextBoxAllModule.ɵfac = function TextBoxAllModule_Factory(t) {
  return new (t || TextBoxAllModule)();
};
TextBoxAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TextBoxAllModule
});
TextBoxAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, TextBoxModule], TextBoxModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextBoxAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TextBoxModule],
      exports: [TextBoxModule],
      providers: []
    }]
  }], null, null);
})();
var TextAreaComponent_1;
const inputs$8 = ['cols', 'cssClass', 'enablePersistence', 'enableRtl', 'enabled', 'floatLabelType', 'htmlAttributes', 'locale', 'maxLength', 'placeholder', 'readonly', 'resizeMode', 'rows', 'showClearButton', 'value', 'width'];
const outputs$9 = ['blur', 'change', 'created', 'destroyed', 'focus', 'input', 'valueChange'];
const twoWays$8 = ['value'];
/**
 * Represents the EJ2 Angular TextArea Component.
 * ```html
 * <ejs-textarea [value]='value'></ejs-textarea>
 * ```
 */
let TextAreaComponent = TextAreaComponent_1 = class TextAreaComponent extends TextArea {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$9);
    this.addTwoWay.call(this, twoWays$8);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
TextAreaComponent.ɵfac = function TextAreaComponent_Factory(t) {
  return new (t || TextAreaComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
TextAreaComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: TextAreaComponent,
  selectors: [["ejs-textarea"]],
  inputs: {
    cols: "cols",
    cssClass: "cssClass",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    floatLabelType: "floatLabelType",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    maxLength: "maxLength",
    placeholder: "placeholder",
    readonly: "readonly",
    resizeMode: "resizeMode",
    rows: "rows",
    showClearButton: "showClearButton",
    value: "value",
    width: "width"
  },
  outputs: {
    blur: "blur",
    change: "change",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    input: "input",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => TextAreaComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function TextAreaComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
TextAreaComponent = TextAreaComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], TextAreaComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextAreaComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-textarea',
      inputs: inputs$8,
      outputs: outputs$9,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => TextAreaComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the TextArea component.
 */
class TextAreaModule {}
TextAreaModule.ɵfac = function TextAreaModule_Factory(t) {
  return new (t || TextAreaModule)();
};
TextAreaModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TextAreaModule
});
TextAreaModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextAreaModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [TextAreaComponent],
      exports: [TextAreaComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the TextArea component with providers.
 */
class TextAreaAllModule {}
TextAreaAllModule.ɵfac = function TextAreaAllModule_Factory(t) {
  return new (t || TextAreaAllModule)();
};
TextAreaAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: TextAreaAllModule
});
TextAreaAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, TextAreaModule], TextAreaModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(TextAreaAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, TextAreaModule],
      exports: [TextAreaModule],
      providers: []
    }]
  }], null, null);
})();
var NumericTextBoxComponent_1;
const inputs$7 = ['cssClass', 'currency', 'currencyCode', 'decimals', 'enablePersistence', 'enableRtl', 'enabled', 'floatLabelType', 'format', 'htmlAttributes', 'locale', 'max', 'min', 'placeholder', 'readonly', 'showClearButton', 'showSpinButton', 'step', 'strictMode', 'validateDecimalOnType', 'value', 'width'];
const outputs$8 = ['blur', 'change', 'created', 'destroyed', 'focus', 'valueChange'];
const twoWays$7 = ['value'];
/**
 * Represents the EJ2 Angular NumericTextBox Component.
 * ```html
 * <ej-numerictextbox [value]='value'></ej-numerictextbox>
 * ```
 */
let NumericTextBoxComponent = NumericTextBoxComponent_1 = class NumericTextBoxComponent extends NumericTextBox {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$8);
    this.addTwoWay.call(this, twoWays$7);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
NumericTextBoxComponent.ɵfac = function NumericTextBoxComponent_Factory(t) {
  return new (t || NumericTextBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
NumericTextBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: NumericTextBoxComponent,
  selectors: [["ejs-numerictextbox"]],
  inputs: {
    cssClass: "cssClass",
    currency: "currency",
    currencyCode: "currencyCode",
    decimals: "decimals",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    floatLabelType: "floatLabelType",
    format: "format",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    max: "max",
    min: "min",
    placeholder: "placeholder",
    readonly: "readonly",
    showClearButton: "showClearButton",
    showSpinButton: "showSpinButton",
    step: "step",
    strictMode: "strictMode",
    validateDecimalOnType: "validateDecimalOnType",
    value: "value",
    width: "width"
  },
  outputs: {
    blur: "blur",
    change: "change",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => NumericTextBoxComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function NumericTextBoxComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
NumericTextBoxComponent = NumericTextBoxComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], NumericTextBoxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericTextBoxComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-numerictextbox',
      inputs: inputs$7,
      outputs: outputs$8,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => NumericTextBoxComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the NumericTextBox component.
 */
class NumericTextBoxModule {}
NumericTextBoxModule.ɵfac = function NumericTextBoxModule_Factory(t) {
  return new (t || NumericTextBoxModule)();
};
NumericTextBoxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NumericTextBoxModule
});
NumericTextBoxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericTextBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [NumericTextBoxComponent],
      exports: [NumericTextBoxComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the NumericTextBox component with providers.
 */
class NumericTextBoxAllModule {}
NumericTextBoxAllModule.ɵfac = function NumericTextBoxAllModule_Factory(t) {
  return new (t || NumericTextBoxAllModule)();
};
NumericTextBoxAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: NumericTextBoxAllModule
});
NumericTextBoxAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, NumericTextBoxModule], NumericTextBoxModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(NumericTextBoxAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, NumericTextBoxModule],
      exports: [NumericTextBoxModule],
      providers: []
    }]
  }], null, null);
})();
var MaskedTextBoxComponent_1;
const inputs$6 = ['cssClass', 'customCharacters', 'enablePersistence', 'enableRtl', 'enabled', 'floatLabelType', 'htmlAttributes', 'locale', 'mask', 'placeholder', 'promptChar', 'readonly', 'showClearButton', 'value', 'width'];
const outputs$7 = ['blur', 'change', 'created', 'destroyed', 'focus', 'valueChange'];
const twoWays$6 = ['value'];
/**
 * Represents the EJ2 Angular MaskedTextbox Component.
 * ```html
 * <ej-maskedtextbox [value]='value'></ej-maskedtextbox>
 * ```
 */
let MaskedTextBoxComponent = MaskedTextBoxComponent_1 = class MaskedTextBoxComponent extends MaskedTextBox {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$7);
    this.addTwoWay.call(this, twoWays$6);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
MaskedTextBoxComponent.ɵfac = function MaskedTextBoxComponent_Factory(t) {
  return new (t || MaskedTextBoxComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
MaskedTextBoxComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: MaskedTextBoxComponent,
  selectors: [["ejs-maskedtextbox"]],
  inputs: {
    cssClass: "cssClass",
    customCharacters: "customCharacters",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    floatLabelType: "floatLabelType",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    mask: "mask",
    placeholder: "placeholder",
    promptChar: "promptChar",
    readonly: "readonly",
    showClearButton: "showClearButton",
    value: "value",
    width: "width"
  },
  outputs: {
    blur: "blur",
    change: "change",
    created: "created",
    destroyed: "destroyed",
    focus: "focus",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => MaskedTextBoxComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function MaskedTextBoxComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
MaskedTextBoxComponent = MaskedTextBoxComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], MaskedTextBoxComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskedTextBoxComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-maskedtextbox',
      inputs: inputs$6,
      outputs: outputs$7,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => MaskedTextBoxComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the MaskedTextBox component.
 */
class MaskedTextBoxModule {}
MaskedTextBoxModule.ɵfac = function MaskedTextBoxModule_Factory(t) {
  return new (t || MaskedTextBoxModule)();
};
MaskedTextBoxModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MaskedTextBoxModule
});
MaskedTextBoxModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskedTextBoxModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [MaskedTextBoxComponent],
      exports: [MaskedTextBoxComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the MaskedTextBox component with providers.
 */
class MaskedTextBoxAllModule {}
MaskedTextBoxAllModule.ɵfac = function MaskedTextBoxAllModule_Factory(t) {
  return new (t || MaskedTextBoxAllModule)();
};
MaskedTextBoxAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: MaskedTextBoxAllModule
});
MaskedTextBoxAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, MaskedTextBoxModule], MaskedTextBoxModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(MaskedTextBoxAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, MaskedTextBoxModule],
      exports: [MaskedTextBoxModule],
      providers: []
    }]
  }], null, null);
})();
var SliderComponent_1;
const inputs$5 = ['colorRange', 'cssClass', 'customValues', 'enableAnimation', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enabled', 'limits', 'locale', 'max', 'min', 'orientation', 'readonly', 'showButtons', 'step', 'ticks', 'tooltip', 'type', 'value', 'width'];
const outputs$6 = ['focus', 'blur', 'change', 'changed', 'created', 'renderedTicks', 'renderingTicks', 'tooltipChange', 'valueChange'];
const twoWays$5 = ['value'];
/**
 * Represents the EJ2 Angular Slider Component.
 * ```html
 * <ejs-slider [value]='value'></ejs-slider>
 * ```
 */
let SliderComponent = SliderComponent_1 = class SliderComponent extends Slider {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$6);
    this.addTwoWay.call(this, twoWays$5);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
SliderComponent.ɵfac = function SliderComponent_Factory(t) {
  return new (t || SliderComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
SliderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SliderComponent,
  selectors: [["ejs-slider"]],
  inputs: {
    colorRange: "colorRange",
    cssClass: "cssClass",
    customValues: "customValues",
    enableAnimation: "enableAnimation",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    limits: "limits",
    locale: "locale",
    max: "max",
    min: "min",
    orientation: "orientation",
    readonly: "readonly",
    showButtons: "showButtons",
    step: "step",
    ticks: "ticks",
    tooltip: "tooltip",
    type: "type",
    value: "value",
    width: "width"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    change: "change",
    changed: "changed",
    created: "created",
    renderedTicks: "renderedTicks",
    renderingTicks: "renderingTicks",
    tooltipChange: "tooltipChange",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SliderComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function SliderComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
SliderComponent = SliderComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], SliderComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SliderComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-slider',
      inputs: inputs$5,
      outputs: outputs$6,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SliderComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the Slider component.
 */
class SliderModule {}
SliderModule.ɵfac = function SliderModule_Factory(t) {
  return new (t || SliderModule)();
};
SliderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SliderModule
});
SliderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SliderModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SliderComponent],
      exports: [SliderComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Slider component with providers.
 */
class SliderAllModule {}
SliderAllModule.ɵfac = function SliderAllModule_Factory(t) {
  return new (t || SliderAllModule)();
};
SliderAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SliderAllModule
});
SliderAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SliderModule], SliderModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SliderAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SliderModule],
      exports: [SliderModule],
      providers: []
    }]
  }], null, null);
})();
let input = ['name', 'size', 'type'];
let outputs$5 = [];
/**
 * 'e-files' directive represent a file of angular uploader
 * It must be contained in a Uploader component(`ejs-uploader`).
 * ```html
 * <ejs-uploader id='fileupload' multiple=true>
 *   <e-files>
 *    <e-file name='Java' size=23000 type='pdf'></e-file>
 *    <e-file name='C++' size=30000 type='.docx'></e-file>
 *   </e-files>
 * </ejs-uploader>
 * ```
 */
class UploadedFilesDirective extends ComplexBase {
  constructor(viewContainerRef) {
    super();
    this.viewContainerRef = viewContainerRef;
    setValue('currentInstance', this, this.viewContainerRef);
    this.registerEvents(outputs$5);
    this.directivePropList = input;
  }
}
UploadedFilesDirective.ɵfac = function UploadedFilesDirective_Factory(t) {
  return new (t || UploadedFilesDirective)(i0.ɵɵdirectiveInject(i0.ViewContainerRef));
};
UploadedFilesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: UploadedFilesDirective,
  selectors: [["e-uploadedfiles"]],
  inputs: {
    name: "name",
    size: "size",
    type: "type"
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploadedFilesDirective, [{
    type: Directive,
    args: [{
      selector: 'e-files>e-uploadedfiles',
      inputs: input,
      outputs: outputs$5,
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ViewContainerRef
    }];
  }, null);
})();
/**
 * UploadedFiles Array Directive
 * @private
 */
class FilesDirective extends ArrayBase {
  constructor() {
    super('files');
  }
}
FilesDirective.ɵfac = function FilesDirective_Factory(t) {
  return new (t || FilesDirective)();
};
FilesDirective.ɵdir = /* @__PURE__ */i0.ɵɵdefineDirective({
  type: FilesDirective,
  selectors: [["e-files"]],
  contentQueries: function FilesDirective_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, UploadedFilesDirective, 4);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.children = _t);
    }
  },
  features: [i0.ɵɵInheritDefinitionFeature]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(FilesDirective, [{
    type: Directive,
    args: [{
      selector: 'ejs-uploader>e-files',
      queries: {
        children: new ContentChildren(UploadedFilesDirective)
      }
    }]
  }], function () {
    return [];
  }, null);
})();
var UploaderComponent_1;
const inputs$4 = ['allowedExtensions', 'asyncSettings', 'autoUpload', 'buttons', 'cssClass', 'directoryUpload', 'dropArea', 'dropEffect', 'enableHtmlSanitizer', 'enablePersistence', 'enableRtl', 'enabled', 'files', 'htmlAttributes', 'locale', 'maxFileSize', 'minFileSize', 'multiple', 'sequentialUpload', 'showFileList', 'template'];
const outputs$4 = ['focus', 'blur', 'actionComplete', 'beforeRemove', 'beforeUpload', 'canceling', 'change', 'chunkFailure', 'chunkSuccess', 'chunkUploading', 'clearing', 'created', 'failure', 'fileListRendering', 'pausing', 'progress', 'removing', 'rendering', 'resuming', 'selected', 'success', 'uploading'];
const twoWays$4 = [];
/**
 * Represents the EJ2 Angular Uploader Component.
 * ```html
 * <ejs-uploader></ejs-uploader>
 * ```
 */
let UploaderComponent = UploaderComponent_1 = class UploaderComponent extends Uploader {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.tags = ['files'];
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$4);
    this.addTwoWay.call(this, twoWays$4);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.tagObjects[0].instance = this.childFiles;
    this.formCompContext.ngAfterContentChecked(this);
  }
};
UploaderComponent.ɵfac = function UploaderComponent_Factory(t) {
  return new (t || UploaderComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
UploaderComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: UploaderComponent,
  selectors: [["ejs-uploader"]],
  contentQueries: function UploaderComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c0, 5);
      i0.ɵɵcontentQuery(dirIndex, FilesDirective, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.template = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.childFiles = _t.first);
    }
  },
  inputs: {
    allowedExtensions: "allowedExtensions",
    asyncSettings: "asyncSettings",
    autoUpload: "autoUpload",
    buttons: "buttons",
    cssClass: "cssClass",
    directoryUpload: "directoryUpload",
    dropArea: "dropArea",
    dropEffect: "dropEffect",
    enableHtmlSanitizer: "enableHtmlSanitizer",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enabled: "enabled",
    files: "files",
    htmlAttributes: "htmlAttributes",
    locale: "locale",
    maxFileSize: "maxFileSize",
    minFileSize: "minFileSize",
    multiple: "multiple",
    sequentialUpload: "sequentialUpload",
    showFileList: "showFileList",
    template: "template"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    actionComplete: "actionComplete",
    beforeRemove: "beforeRemove",
    beforeUpload: "beforeUpload",
    canceling: "canceling",
    change: "change",
    chunkFailure: "chunkFailure",
    chunkSuccess: "chunkSuccess",
    chunkUploading: "chunkUploading",
    clearing: "clearing",
    created: "created",
    failure: "failure",
    fileListRendering: "fileListRendering",
    pausing: "pausing",
    progress: "progress",
    removing: "removing",
    rendering: "rendering",
    resuming: "resuming",
    selected: "selected",
    success: "success",
    uploading: "uploading"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => UploaderComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  decls: 0,
  vars: 0,
  template: function UploaderComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], UploaderComponent.prototype, "template", void 0);
UploaderComponent = UploaderComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], UploaderComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploaderComponent, [{
    type: Component,
    args: [{
      selector: 'ejs-uploader',
      inputs: inputs$4,
      outputs: outputs$4,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => UploaderComponent),
        multi: true
      }],
      queries: {
        childFiles: new ContentChild(FilesDirective)
      }
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    template: [{
      type: ContentChild,
      args: ['template']
    }]
  });
})();

/**
 * NgModule definition for the Uploader component.
 */
class UploaderModule {}
UploaderModule.ɵfac = function UploaderModule_Factory(t) {
  return new (t || UploaderModule)();
};
UploaderModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UploaderModule
});
UploaderModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploaderModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [UploaderComponent, UploadedFilesDirective, FilesDirective],
      exports: [UploaderComponent, UploadedFilesDirective, FilesDirective]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Uploader component with providers.
 */
class UploaderAllModule {}
UploaderAllModule.ɵfac = function UploaderAllModule_Factory(t) {
  return new (t || UploaderAllModule)();
};
UploaderAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: UploaderAllModule
});
UploaderAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, UploaderModule], UploaderModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(UploaderAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, UploaderModule],
      exports: [UploaderModule],
      providers: []
    }]
  }], null, null);
})();
var ColorPickerComponent_1;
const inputs$3 = ['columns', 'createPopupOnClick', 'cssClass', 'disabled', 'enableOpacity', 'enablePersistence', 'enableRtl', 'inline', 'locale', 'mode', 'modeSwitcher', 'noColor', 'presetColors', 'showButtons', 'value'];
const outputs$3 = ['focus', 'blur', 'beforeClose', 'beforeModeSwitch', 'beforeOpen', 'beforeTileRender', 'change', 'created', 'onModeSwitch', 'open', 'select', 'valueChange'];
const twoWays$3 = ['value'];
/**
 * Represents the EJ2 Angular ColorPicker Component.
 * ```html
 * <input ejs-colorpicker type='color'/>
 * ```
 */
let ColorPickerComponent = ColorPickerComponent_1 = class ColorPickerComponent extends ColorPicker {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$3);
    this.addTwoWay.call(this, twoWays$3);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
ColorPickerComponent.ɵfac = function ColorPickerComponent_Factory(t) {
  return new (t || ColorPickerComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
ColorPickerComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: ColorPickerComponent,
  selectors: [["", "ejs-colorpicker", ""]],
  inputs: {
    columns: "columns",
    createPopupOnClick: "createPopupOnClick",
    cssClass: "cssClass",
    disabled: "disabled",
    enableOpacity: "enableOpacity",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    inline: "inline",
    locale: "locale",
    mode: "mode",
    modeSwitcher: "modeSwitcher",
    noColor: "noColor",
    presetColors: "presetColors",
    showButtons: "showButtons",
    value: "value"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    beforeClose: "beforeClose",
    beforeModeSwitch: "beforeModeSwitch",
    beforeOpen: "beforeOpen",
    beforeTileRender: "beforeTileRender",
    change: "change",
    created: "created",
    onModeSwitch: "onModeSwitch",
    open: "open",
    select: "select",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => ColorPickerComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c1,
  decls: 0,
  vars: 0,
  template: function ColorPickerComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
ColorPickerComponent = ColorPickerComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], ColorPickerComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorPickerComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-colorpicker]',
      inputs: inputs$3,
      outputs: outputs$3,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => ColorPickerComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the ColorPicker component.
 */
class ColorPickerModule {}
ColorPickerModule.ɵfac = function ColorPickerModule_Factory(t) {
  return new (t || ColorPickerModule)();
};
ColorPickerModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ColorPickerModule
});
ColorPickerModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorPickerModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [ColorPickerComponent],
      exports: [ColorPickerComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the ColorPicker component with providers.
 */
class ColorPickerAllModule {}
ColorPickerAllModule.ɵfac = function ColorPickerAllModule_Factory(t) {
  return new (t || ColorPickerAllModule)();
};
ColorPickerAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: ColorPickerAllModule
});
ColorPickerAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, ColorPickerModule], ColorPickerModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ColorPickerAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, ColorPickerModule],
      exports: [ColorPickerModule],
      providers: []
    }]
  }], null, null);
})();
var SignatureComponent_1;
const inputs$2 = ['backgroundColor', 'backgroundImage', 'disabled', 'enablePersistence', 'enableRtl', 'isReadOnly', 'locale', 'maxStrokeWidth', 'minStrokeWidth', 'saveWithBackground', 'strokeColor', 'velocity'];
const outputs$2 = ['focus', 'blur', 'beforeSave', 'change', 'created'];
const twoWays$2 = [];
/**
 * Represents the EJ2 Angular Signature Component.
 * ```html
 * <canvas ejs-signature />
 * ```
 */
let SignatureComponent = SignatureComponent_1 = class SignatureComponent extends Signature {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$2);
    this.addTwoWay.call(this, twoWays$2);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
SignatureComponent.ɵfac = function SignatureComponent_Factory(t) {
  return new (t || SignatureComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
SignatureComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: SignatureComponent,
  selectors: [["", "ejs-signature", ""]],
  inputs: {
    backgroundColor: "backgroundColor",
    backgroundImage: "backgroundImage",
    disabled: "disabled",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    isReadOnly: "isReadOnly",
    locale: "locale",
    maxStrokeWidth: "maxStrokeWidth",
    minStrokeWidth: "minStrokeWidth",
    saveWithBackground: "saveWithBackground",
    strokeColor: "strokeColor",
    velocity: "velocity"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    beforeSave: "beforeSave",
    change: "change",
    created: "created"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SignatureComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c2,
  decls: 0,
  vars: 0,
  template: function SignatureComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
SignatureComponent = SignatureComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], SignatureComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignatureComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-signature]',
      inputs: inputs$2,
      outputs: outputs$2,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => SignatureComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the Signature component.
 */
class SignatureModule {}
SignatureModule.ɵfac = function SignatureModule_Factory(t) {
  return new (t || SignatureModule)();
};
SignatureModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SignatureModule
});
SignatureModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignatureModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [SignatureComponent],
      exports: [SignatureComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Signature component with providers.
 */
class SignatureAllModule {}
SignatureAllModule.ɵfac = function SignatureAllModule_Factory(t) {
  return new (t || SignatureAllModule)();
};
SignatureAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: SignatureAllModule
});
SignatureAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, SignatureModule], SignatureModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(SignatureAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, SignatureModule],
      exports: [SignatureModule],
      providers: []
    }]
  }], null, null);
})();
var RatingComponent_1;
const inputs$1 = ['allowReset', 'cssClass', 'disabled', 'emptyTemplate', 'enableAnimation', 'enablePersistence', 'enableRtl', 'enableSingleSelection', 'fullTemplate', 'itemsCount', 'labelPosition', 'labelTemplate', 'locale', 'min', 'precision', 'readOnly', 'showLabel', 'showTooltip', 'tooltipTemplate', 'value', 'visible'];
const outputs$1 = ['focus', 'blur', 'beforeItemRender', 'created', 'onItemHover', 'valueChanged', 'valueChange'];
const twoWays$1 = ['value'];
/**
 * Represents the EJ2 Angular Rating Component.
 * ```html
 * <input ejs-rating [value]='value' />
 * ```
 */
let RatingComponent = RatingComponent_1 = class RatingComponent extends Rating {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs$1);
    this.addTwoWay.call(this, twoWays$1);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
RatingComponent.ɵfac = function RatingComponent_Factory(t) {
  return new (t || RatingComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
RatingComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: RatingComponent,
  selectors: [["", "ejs-rating", ""]],
  contentQueries: function RatingComponent_ContentQueries(rf, ctx, dirIndex) {
    if (rf & 1) {
      i0.ɵɵcontentQuery(dirIndex, _c3, 5);
      i0.ɵɵcontentQuery(dirIndex, _c4, 5);
      i0.ɵɵcontentQuery(dirIndex, _c5, 5);
      i0.ɵɵcontentQuery(dirIndex, _c6, 5);
    }
    if (rf & 2) {
      let _t;
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.fullTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.emptyTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.tooltipTemplate = _t.first);
      i0.ɵɵqueryRefresh(_t = i0.ɵɵloadQuery()) && (ctx.labelTemplate = _t.first);
    }
  },
  inputs: {
    allowReset: "allowReset",
    cssClass: "cssClass",
    disabled: "disabled",
    emptyTemplate: "emptyTemplate",
    enableAnimation: "enableAnimation",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    enableSingleSelection: "enableSingleSelection",
    fullTemplate: "fullTemplate",
    itemsCount: "itemsCount",
    labelPosition: "labelPosition",
    labelTemplate: "labelTemplate",
    locale: "locale",
    min: "min",
    precision: "precision",
    readOnly: "readOnly",
    showLabel: "showLabel",
    showTooltip: "showTooltip",
    tooltipTemplate: "tooltipTemplate",
    value: "value",
    visible: "visible"
  },
  outputs: {
    focus: "focus",
    blur: "blur",
    beforeItemRender: "beforeItemRender",
    created: "created",
    onItemHover: "onItemHover",
    valueChanged: "valueChanged",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => RatingComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c7,
  decls: 0,
  vars: 0,
  template: function RatingComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
__decorate([Template()], RatingComponent.prototype, "fullTemplate", void 0);
__decorate([Template()], RatingComponent.prototype, "emptyTemplate", void 0);
__decorate([Template()], RatingComponent.prototype, "tooltipTemplate", void 0);
__decorate([Template()], RatingComponent.prototype, "labelTemplate", void 0);
RatingComponent = RatingComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], RatingComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-rating]',
      inputs: inputs$1,
      outputs: outputs$1,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => RatingComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, {
    fullTemplate: [{
      type: ContentChild,
      args: ['fullTemplate']
    }],
    emptyTemplate: [{
      type: ContentChild,
      args: ['emptyTemplate']
    }],
    tooltipTemplate: [{
      type: ContentChild,
      args: ['tooltipTemplate']
    }],
    labelTemplate: [{
      type: ContentChild,
      args: ['labelTemplate']
    }]
  });
})();

/**
 * NgModule definition for the Rating component.
 */
class RatingModule {}
RatingModule.ɵfac = function RatingModule_Factory(t) {
  return new (t || RatingModule)();
};
RatingModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RatingModule
});
RatingModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [RatingComponent],
      exports: [RatingComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the Rating component with providers.
 */
class RatingAllModule {}
RatingAllModule.ɵfac = function RatingAllModule_Factory(t) {
  return new (t || RatingAllModule)();
};
RatingAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: RatingAllModule
});
RatingAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, RatingModule], RatingModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(RatingAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, RatingModule],
      exports: [RatingModule],
      providers: []
    }]
  }], null, null);
})();
var OtpInputComponent_1;
const inputs = ['ariaLabels', 'cssClass', 'disabled', 'enablePersistence', 'enableRtl', 'htmlAttributes', 'length', 'locale', 'placeholder', 'separator', 'stylingMode', 'type', 'value'];
const outputs = ['blur', 'created', 'focus', 'input', 'valueChanged', 'valueChange'];
const twoWays = ['value'];
/**
 * Represents the EJ2 Angular OtpInput Component.
 * ```html
 * <div ejs-otpinput [value]='value'></div>
 * ```
 */
let OtpInputComponent = OtpInputComponent_1 = class OtpInputComponent extends OtpInput {
  constructor(ngEle, srenderer, viewContainerRef, injector, cdr) {
    super();
    this.ngEle = ngEle;
    this.srenderer = srenderer;
    this.viewContainerRef = viewContainerRef;
    this.injector = injector;
    this.cdr = cdr;
    this.skipFromEvent = true;
    this.element = this.ngEle.nativeElement;
    this.injectedModules = this.injectedModules || [];
    this.registerEvents(outputs);
    this.addTwoWay.call(this, twoWays);
    setValue('currentInstance', this, this.viewContainerRef);
    this.formContext = new FormBase();
    this.formCompContext = new ComponentBase();
  }
  registerOnChange(registerFunction) {}
  registerOnTouched(registerFunction) {}
  writeValue(value) {}
  setDisabledState(disabled) {}
  ngOnInit() {
    this.formCompContext.ngOnInit(this);
  }
  ngAfterViewInit() {
    this.formContext.ngAfterViewInit(this);
  }
  ngOnDestroy() {
    this.formCompContext.ngOnDestroy(this);
  }
  ngAfterContentChecked() {
    this.formCompContext.ngAfterContentChecked(this);
  }
};
OtpInputComponent.ɵfac = function OtpInputComponent_Factory(t) {
  return new (t || OtpInputComponent)(i0.ɵɵdirectiveInject(i0.ElementRef), i0.ɵɵdirectiveInject(i0.Renderer2), i0.ɵɵdirectiveInject(i0.ViewContainerRef), i0.ɵɵdirectiveInject(i0.Injector), i0.ɵɵdirectiveInject(i0.ChangeDetectorRef));
};
OtpInputComponent.ɵcmp = /* @__PURE__ */i0.ɵɵdefineComponent({
  type: OtpInputComponent,
  selectors: [["", "ejs-otpinput", ""]],
  inputs: {
    ariaLabels: "ariaLabels",
    cssClass: "cssClass",
    disabled: "disabled",
    enablePersistence: "enablePersistence",
    enableRtl: "enableRtl",
    htmlAttributes: "htmlAttributes",
    length: "length",
    locale: "locale",
    placeholder: "placeholder",
    separator: "separator",
    stylingMode: "stylingMode",
    type: "type",
    value: "value"
  },
  outputs: {
    blur: "blur",
    created: "created",
    focus: "focus",
    input: "input",
    valueChanged: "valueChanged",
    valueChange: "valueChange"
  },
  features: [i0.ɵɵProvidersFeature([{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => OtpInputComponent_1),
    multi: true
  }]), i0.ɵɵInheritDefinitionFeature],
  attrs: _c8,
  decls: 0,
  vars: 0,
  template: function OtpInputComponent_Template(rf, ctx) {},
  encapsulation: 2,
  changeDetection: 0
});
OtpInputComponent = OtpInputComponent_1 = __decorate([ComponentMixins([ComponentBase, FormBase])], OtpInputComponent);
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OtpInputComponent, [{
    type: Component,
    args: [{
      selector: '[ejs-otpinput]',
      inputs: inputs,
      outputs: outputs,
      template: '',
      changeDetection: ChangeDetectionStrategy.OnPush,
      providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: forwardRef(() => OtpInputComponent),
        multi: true
      }],
      queries: {}
    }]
  }], function () {
    return [{
      type: i0.ElementRef
    }, {
      type: i0.Renderer2
    }, {
      type: i0.ViewContainerRef
    }, {
      type: i0.Injector
    }, {
      type: i0.ChangeDetectorRef
    }];
  }, null);
})();

/**
 * NgModule definition for the OtpInput component.
 */
class OtpInputModule {}
OtpInputModule.ɵfac = function OtpInputModule_Factory(t) {
  return new (t || OtpInputModule)();
};
OtpInputModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OtpInputModule
});
OtpInputModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  imports: [[CommonModule]]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OtpInputModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule],
      declarations: [OtpInputComponent],
      exports: [OtpInputComponent]
    }]
  }], null, null);
})();

/**
 * NgModule definition for the OtpInput component with providers.
 */
class OtpInputAllModule {}
OtpInputAllModule.ɵfac = function OtpInputAllModule_Factory(t) {
  return new (t || OtpInputAllModule)();
};
OtpInputAllModule.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: OtpInputAllModule
});
OtpInputAllModule.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [],
  imports: [[CommonModule, OtpInputModule], OtpInputModule]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(OtpInputAllModule, [{
    type: NgModule,
    args: [{
      imports: [CommonModule, OtpInputModule],
      exports: [OtpInputModule],
      providers: []
    }]
  }], null, null);
})();
class FormValidators {
  //max validation
  static max(number) {
    let max = number;
    return control => {
      //tslint:disable-next-line
      let result = FormValidator.checkValidator.max({
        value: control.value,
        param: max
      });
      if (result === true) {
        return null;
      } else {
        return {
          'max': true
        };
      }
    };
  }
  // min validation
  static min(number) {
    let min = number;
    return control => {
      //tslint:disable-next-line
      let result = FormValidator.checkValidator.min({
        value: control.value,
        param: min
      });
      if (result === true) {
        return null;
      } else {
        return {
          'min': true
        };
      }
    };
  }
  // Credit card validation
  static creditcard(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.creditcard({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'cardno': true
      };
    }
  }
  // date validation
  static date(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.date({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'date': true
      };
    }
  }
  // Date-ISO validation
  static dateIso(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.dateIso({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'dateiso': true
      };
    }
  }
  // Digit validation
  static digits(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.digits({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'digit': true
      };
    }
  }
  // Email validation
  static email(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.email({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'email': true
      };
    }
  }
  //maxlength validation
  static maxLength(number) {
    let maxlength = number;
    return control => {
      //tslint:disable-next-line
      let result = FormValidator.checkValidator.maxLength({
        value: control.value,
        param: maxlength
      });
      if (result === true) {
        return null;
      } else {
        return {
          'maxlength': true
        };
      }
    };
  }
  //minlength validation
  static minLength(number) {
    let minlength = number;
    return control => {
      //tslint:disable-next-line
      let result = FormValidator.checkValidator.minLength({
        value: control.value,
        param: minlength
      });
      if (result === true) {
        return null;
      } else {
        return {
          'minlength': true
        };
      }
    };
  }
  //number validation
  static number(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.number({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'number': true
      };
    }
  }
  // required validation
  static required(control) {
    //tslint:disable-next-line
    let result = control.value === null ? false : FormValidator.checkValidator.required({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'required': true
      };
    }
  }
  // Telephone number validation
  static tel(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.tel({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'telno': true
      };
    }
  }
  // Url validation
  static url(control) {
    //tslint:disable-next-line
    let result = FormValidator.checkValidator.url({
      value: control.value
    });
    if (result === true) {
      return null;
    } else {
      return {
        'url': true
      };
    }
  }
  // RangeLength validation
  static rangeLength(number1, number2) {
    let minRL = number1;
    let maxRL = number2;
    //tslint:disable-next-line
    let param = [minRL, maxRL];
    return control => {
      //tslint:disable-next-line
      let result = FormValidator.checkValidator.rangeLength({
        value: control.value,
        param: param
      });
      if (result === true) {
        return null;
      } else {
        return {
          'rangelength': true
        };
      }
    };
  }
  // Range validation
  static range(number1, number2) {
    let minR = number1;
    let maxR = number2;
    //tslint:disable-next-line
    let param1 = [minR, maxR];
    return control => {
      //tslint:disable-next-line
      let result = FormValidator.checkValidator.range({
        value: control.value,
        param: param1
      });
      if (result === true) {
        return null;
      } else {
        return {
          'range': true
        };
      }
    };
  }
}

/**
 * Generated bundle index. Do not edit.
 */

export { ColorPickerAllModule, ColorPickerComponent, ColorPickerModule, FilesDirective, FormValidators, MaskedTextBoxAllModule, MaskedTextBoxComponent, MaskedTextBoxModule, NumericTextBoxAllModule, NumericTextBoxComponent, NumericTextBoxModule, OtpInputAllModule, OtpInputComponent, OtpInputModule, RatingAllModule, RatingComponent, RatingModule, SignatureAllModule, SignatureComponent, SignatureModule, SliderAllModule, SliderComponent, SliderModule, TextAreaAllModule, TextAreaComponent, TextAreaModule, TextBoxAllModule, TextBoxComponent, TextBoxModule, UploadedFilesDirective, UploaderAllModule, UploaderComponent, UploaderModule };
