import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { PositionDto } from '../model/position-model';
import { getEnumKeyByEnumValue, showErrorDialog } from '../shared/utils';
import { environment } from '../../environments/environment';
import { PositionStatsDto } from '../model/position-stats-model';
import { Location } from '../model/enums/location';

@Injectable({
  providedIn: 'root'
})
export class PositionsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/positions`;
  private refreshTrigger = new BehaviorSubject<void>(void 0);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
  ) { }


  public getPosition$(id: string): Observable<PositionDto> {

    return this.http.get<PositionDto>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getCurrentUserPositions$(): Observable<PositionDto[]> {

    return this.http.get<PositionDto[]>(`${this.apiEndpoint}/current-user`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public getPositionStats$(id: string): Observable<PositionStatsDto> {

    return this.http.get<PositionStatsDto>(`${this.apiEndpoint}/${id}/stats`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public deletePosition$(id: string): Observable<void> {
    return this.http.delete<void>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public createPosition$(position: PositionDto): Observable<PositionDto> {
    return this.http.post<PositionDto>(this.apiEndpoint, position).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public updatePosition$(id: string, position: PositionDto): Observable<PositionDto> {
    position.id = id;
    return this.http.put<PositionDto>(`${this.apiEndpoint}/update/${id}`, position).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public closePosition$(id: string): Observable<PositionDto> {
    return this.http.put<PositionDto>(`${this.apiEndpoint}/${id}/close`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public openPosition$(id: string): Observable<PositionDto> {
    return this.http.put<PositionDto>(`${this.apiEndpoint}/${id}/reopen`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  getAllPositions$(): Observable<PositionDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<PositionDto[]>(this.apiEndpoint).pipe(
          catchError((error: any) => {

            this.logger.error(error);
            showErrorDialog(this.errorDialog, error.message);
            return throwError(() => new Error(error.message));

          })
        );
      }),
      catchError((error) => throwError(() => new Error(error.message)))
    );
  }

  getAllDubaiPositions$(): Observable<PositionDto[]> {
    const locationKey = getEnumKeyByEnumValue(Location, Location.Dubai);

    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<PositionDto[]>(this.apiEndpoint + "/location/" + locationKey).pipe(
          catchError((error: any) => {

            this.logger.error(error);
            showErrorDialog(this.errorDialog, error.message);
            return throwError(() => new Error(error.message));

          })
        );
      }),
      catchError((error) => throwError(() => new Error(error.message)))
    );
  }

  getAllHKPositions$(): Observable<PositionDto[]> {
    const locationKey = getEnumKeyByEnumValue(Location, Location.HongKong);

    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<PositionDto[]>(this.apiEndpoint + "/location/" + locationKey).pipe(
          catchError((error: any) => {

            this.logger.error(error);
            showErrorDialog(this.errorDialog, error.message);
            return throwError(() => new Error(error.message));

          })
        );
      }),
      catchError((error) => throwError(() => new Error(error.message)))
    );
  }

  getAllGenevaPositions$(): Observable<PositionDto[]> {
    const locationKey = getEnumKeyByEnumValue(Location, Location.Geneva);

    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<PositionDto[]>(this.apiEndpoint + "/location/" + locationKey).pipe(
          catchError((error: any) => {

            this.logger.error(error);
            showErrorDialog(this.errorDialog, error.message);
            return throwError(() => new Error(error.message));

          })
        );
      }),
      catchError((error) => throwError(() => new Error(error.message)))
    );
  }

  total$(): Observable<number> {
    return this.getAllPositions$().pipe(
      map((positions) => positions.length),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
