import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { PositionsService } from '../../services/positions.service';
import { Router } from '@angular/router';
import { PositionFormService } from '../../services/position-form.service';
import { PositionFormComponent } from '../form/position-form.component';


@Component({
  selector: 'create-position',
  templateUrl: './create-position.component.html',
  styleUrl: './create-position.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    PositionFormComponent
  ],
  providers: []
})
export class CreatePositionComponent {
  isEditMode: boolean = false;

  constructor(private positionsService: PositionsService, private router: Router,
    private positionsFormService: PositionFormService) { }

  ngOnInit() { }

  onSubmit(formData: FormGroup) {
    if (formData.valid) {
      const pos = formData.value;
      // If deadline ongoing checkbox is true, set deadline date to NULL
      if (pos.deadlineOngoing) {
        pos.deadline = null;
      }
      // If start date asap checkbox is true, set start date to NULL
      if (pos.startDateAsap) {
        pos.startDate = null;
      }
      
      this.positionsService.createPosition$(pos).subscribe(() => {
        this.positionsFormService.reset(formData);
        this.router.navigate(["positions"]);
      });
    }
  }
}
