import { Component, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDto } from '../../model/application-model';
import { ApplicationsService } from '../../services/applications.service';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ApplicationStatus } from '../../model/enums/application-status';
import { InterviewCardComponent } from '../interview-card/interview-card.component';
import { FormsModule } from '@angular/forms';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { TextBoxModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { CareersAuthService } from '../../../auth/careers-auth.service';
import { AuthenticatedUser } from '../../model/user.model';
import { InterviewDto } from '../../model/interview-model';
import { getApplicationStatusValue, getLocationValue, getTeamValue } from '../../shared/utils';
import { SpinnerComponent } from '../../shared/spinner.component';

@Component({
  selector: 'app-application-detail',
  templateUrl: './application-detail.component.html',
  styleUrl: './application-detail.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ListViewModule,
    InterviewCardComponent,
    FormsModule,
    DialogModule,
    TextBoxModule,
    RichTextEditorAllModule,
    SpinnerComponent
  ],
  providers: []
})
export class ApplicationDetailComponent {
  application: ApplicationDto;
  applicationHistory?: ApplicationDto[];
  notes: string = '';
  loading: boolean = true;
  @ViewChild('rejectDialog') rejectDialog: DialogComponent;
  @ViewChild('approveHrPreScreeningDialog') approveHrPreScreeningDialog: DialogComponent;
  @ViewChild('onHoldDialog') onHoldDialog: DialogComponent;
  @ViewChild('deleteDialog') deleteDialog: DialogComponent;

  constructor(
    private route: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private authService: CareersAuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getApplicationIdFromUrl();

  }

  getApplicationIdFromUrl() {
    const urlSegments = this.route.snapshot.url;
    const lastSegmentIndex = urlSegments.length - 1;
    const applicationId = urlSegments[lastSegmentIndex].path;

    this.applicationsService.getApplication$(applicationId).subscribe((application) => {
      this.application = application;
      this.loading = false;
    });
  }

  openDialog(dialog: DialogComponent) {
    dialog.show();
  }

  closeDialog(dialog: DialogComponent) {
    dialog.hide();
  }

  confirmAction(dialog: DialogComponent, action: string) {
    if (action === 'reject') {
      this.rejectApplication();
    } else if (action === 'approveHrPreScreeningDialog') {
      this.approveHRPreScreening();
    } else if (action === 'onHold') {
      this.putOnHold();
    } else if (action === 'delete') {
      this.deleteApplication();
    }
    this.closeDialog(dialog);
  }

  onApplicationUpdated(updatedApplication: ApplicationDto) {
    this.application = updatedApplication;
  }

  getTeamValue(enumKey: string) {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string) {
    return getLocationValue(enumKey);
  }

  approveHRPreScreening() {
    this.applicationsService.approveHRPreScreening$(this.application.id, this.notes).subscribe((application) => {
      this.application = application;
    });
  }

  makeOffer() {
    this.applicationsService.makeoffer$(this.application.id).subscribe((application) => {
      this.application = application;
    });
  }

  acceptOffer() {
    this.applicationsService.acceptOffer$(this.application.id).subscribe((application) => {
      this.application = application;
    });
  }

  rejectOffer() {
    this.applicationsService.rejectOffer$(this.application.id).subscribe((application) => {
      this.application = application;
    });
  }

  canClickApproveHRPreScreening(): boolean {
    return this.application.status === ApplicationStatus.Submitted && this.authService.isHR();
  }

  canFinalizeApplication(): boolean {
    return this.authService.isHR();
  }

  canClickPutOnHold(): boolean {
    return this.application.status === ApplicationStatus.OfferAccepted ||
      this.application.status === ApplicationStatus.OnHold ||
      this.application.status === ApplicationStatus.Rejected;
  }

  showApproveHRPreScreeeningButton() {
    return this.application.status == ApplicationStatus.Submitted && this.application.position.hrPrescreening;
  }

  showApproveToFirstInterviewButton() {
    const value = ApplicationStatus[this.application.status as keyof typeof ApplicationStatus];

    return value == ApplicationStatus.PassedHRPreScreening ||
      this.application.status == ApplicationStatus.Submitted && !this.application.position.hrPrescreening;
  }

  canClickReject(): boolean {
    return this.application.status != ApplicationStatus.Rejected &&
      this.application.status != ApplicationStatus.OfferAccepted;
  }

  canClickApproveToFirstInterview(): boolean {
    if (this.application?.position?.interviewProcess?.rounds[0]?.interviewers != undefined) {
      const interviewers = this.application.position.interviewProcess.rounds[0].interviewers;
      const value = ApplicationStatus[this.application.status as keyof typeof ApplicationStatus];

      const isInterviewer = interviewers.some(interviewer => interviewer.email === this.authService.account.username);

      // Compare application.status with the key of ApplicationStatus.PassedHRPreScreening
      return ((isInterviewer && value === ApplicationStatus.PassedHRPreScreening) ||
        (isInterviewer && this.application.status === ApplicationStatus.Submitted && !this.application.position.hrPrescreening)) &&
        !this.application.screeningApprovedBy.includes(this.authService.account.username);
    } else {
      return false;
    }
  }

  canClickDeleteApplication() {
    return this.authService.isHR();
  }

  approveToInterview() {
    this.applicationsService.approveToInterview$(this.application.id).subscribe((application) => {
      this.application = application;
    });
  }

  rejectApplication() {
    this.applicationsService.rejectApplication$(this.application.id, this.notes).subscribe((application) => {
      this.application = application;
    });
  }

  navigateToInterviewDetail(interview: InterviewDto) {
    this.router.navigate(['/interviews', 'details', interview.id]);
  }


  deleteApplication() {
    this.applicationsService.deleteApplication$(this.application.id).subscribe(() => {
      this.router.navigate(['applications']);
    });
  }

  getApplicationStatusValue(enumKey: string) {
    return getApplicationStatusValue(enumKey);
  }

  putOnHold() {
    this.applicationsService.putOnHold$(this.application.id, this.notes).subscribe((application) => {
      this.application = application;
    });
  }
}
