<div class="card-layout">
  <div class="card">
    <div class="card-body">
      <form [formGroup]="applicationForm" novalidate>
        <div style="display: flex; flex-direction: column; gap: 50px">
          <!-- Upload CV -->
          <ejs-uploader
            #defaultupload
            id="defaultfileupload"
            dropArea="ejs-uploader"
            (select)="onFileRemove($event)"
            (selected)="parseCvInApi($event)"
            allowedExtensions=".pdf"
          ></ejs-uploader>
          <div *ngIf="parsingLoading$">
            <div>Parsing CV</div>
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                [style.width]="value + '%'"
              ></div>
            </div>
          </div>
          <!-- Display extracted data from CV for Review -->
          <div style="display: grid; grid-template-columns: auto auto; column-gap: 50px; margin-bottom: 20px">
            <ejs-pdfviewer
              #pdfviewer
              id="pdfViewer"
              [documentPath]="document"
              [resourceUrl]="resource"
              style="height: 500px"
              [style.display]="parsed$ ? 'block' : 'none'"
            ></ejs-pdfviewer>
            <div *ngIf="parsed$">
              <div formGroupName="candidate">
                <div class="form-group" style="margin-top: 50px">
                  <label class="label">Full name<span class="asterisk">*</span></label>
                  <input
                    style="width: 100%; margin-bottom: 10px"
                    class="form-control"
                    name="fn"
                    formControlName="displayName"
                    placeholder="Name"
                    required
                    fullWidth
                  />
                </div>
                <div class="form-group">
                  <label class="label">Email<span class="asterisk">*</span></label>
                  <input
                    style="width: 100%; margin-bottom: 10px"
                    class="form-control"
                    name="eml"
                    formControlName="mail"
                    placeholder="Email"
                    required
                    fullWidth
                  />
                </div>
                <div class="form-group">
                  <label class="label">Phone no.<span class="asterisk">*</span></label>
                  <input
                    style="width: 100%; margin-bottom: 10px"
                    class="form-control"
                    name="ph"
                    formControlName="mobilePhone"
                    placeholder="Phone no."
                    required
                    fullWidth
                  />
                </div>
                <div class="form-group">
                  <label class="label">Location<span class="asterisk">*</span></label>
                  <input
                    style="width: 100%; margin-bottom: 10px"
                    class="form-control"
                    name="lcn"
                    formControlName="country"
                    placeholder="Location"
                    required
                    fullWidth
                  />
                </div>
                <div class="form-group">
                  <label class="label">LinkedIn</label>
                  <input
                    style="width: 100%; margin-bottom: 10px"
                    class="form-control"
                    name="lkd"
                    formControlName="linkedIn"
                    placeholder="LinkedIn"
                    fullWidth
                  />
                </div>

                <!-- Positions Select Dropdown -->
              </div>
              <div class="form-group">
                <label class="label">Most Recent Company</label>
                <input
                  style="width: 100%; margin-bottom: 10px"
                  class="form-control"
                  name="mrc"
                  formControlName="mostRecentCompany"
                  placeholder="Most Recent Company"
                  fullWidth
                />
              </div>
              <div class="form-group">
                <label class="label">Most Recent Job Title</label>
                <input
                  style="width: 100%; margin-bottom: 10px"
                  class="form-control"
                  name="mrjt"
                  formControlName="mostRecentJobTitle"
                  placeholder="Most Recent Job Title"
                  fullWidth
                />
              </div>
              <div class="form-group">
                <label class="label">Expected Salary</label>
                <input
                  style="width: 100%; margin-bottom: 10px"
                  class="form-control"
                  name="expslry"
                  formControlName="expectedSalary"
                  placeholder="Expected Salary"
                  fullWidth
                />
              </div>
              <div class="form-group">
                <label class="label">Availability</label>
                <input
                  style="width: 100%; margin-bottom: 10px"
                  class="form-control"
                  name="availability"
                  formControlName="availability"
                  placeholder="Availability"
                  fullWidth
                />
              </div>
              <div class="mb-4">
                <select
                  class="form-select position-select"
                  style="width: 100%; margin-top: 50px; margin-right: 20px"
                  placeholder="Select position"
                  (change)="setPositionDetails($event)"
                >
                  <option *ngFor="let position of positions" [value]="position.id">
                    {{ position.jobTitle }} - {{ position.team }} |
                    {{ position.location }}
                  </option>
                </select>
              </div>
              <div formArrayName="answers" class="questions-section" *ngIf="selectedPosition">
                <div *ngFor="let question of selectedPosition.questions; let i = index" class="form-group">
                  <app-question-answer [aggregateInterface]="getAggregateInterface(i)"></app-question-answer>
                </div>
              </div>

              <!-- Submit Button -->
              <button
                ejs-progressbutton
                type="submit"
                [disabled]="submitLoading"
                class="btn btn-primary"
                (click)="submitApplication()"
              >
                Submit Application
              </button>
            </div>
          </div>
          <div *ngIf="submitLoading">
            <div>Submitting application</div>
            <div class="progress">
              <div
                class="progress-bar progress-bar-striped progress-bar-animated"
                role="progressbar"
                [style.width]="value + '%'"
              ></div>
            </div>
          </div>
          <div *ngIf="submitDone$">
            <div><b>Application has been submitted!</b></div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
