import { Component, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { Location } from '../../model/enums/location';
import { RecruitingMode } from '../../model/enums/recruiting-mode';
import { Team } from '../../model/enums/team';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NavigationExtras, Router } from '@angular/router';
import { JobType } from '../../model/enums/job-type';
import { Observable } from 'rxjs';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from '../../shared/sortable.directive';

import {
  Column,
  FilterService,
  GridModule,
  GroupService,
  PageService,
  PageSettingsModel,
  QueryCellInfoEventArgs,
  ResizeService,
  SelectionSettingsModel,
  SortService
} from '@syncfusion/ej2-angular-grids';
import { style } from '@angular/animations';
import { ApplicationDto } from '../../model/application-model';
import { DropDownList, DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { PositionsService } from '../../services/positions.service';
import { PositionDto } from '../../model/position-model';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { InterviewDto } from '../../model/interview-model';
import { InterviewStatus } from '../../model/enums/interview-status';
import { InterviewsService } from '../../services/interviews.service';
import { ApplicationsService } from '../../services/applications.service';
import { CareersAuthService } from '../../../auth/careers-auth.service';
import { getLocationValue, getTeamValue } from '../../shared/utils';
import { SpinnerComponent } from '../../shared/spinner.component';

@Component({
  selector: 'list-interview',
  templateUrl: './interview-list.component.html',
  styleUrl: './interview-list.component.css',
  standalone: true,
  imports: [
    MatIconModule,
    MatListModule,
    MatDividerModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    NgbPaginationModule,
    GridModule,
    DropDownListModule,
    CheckBoxModule,
    SpinnerComponent
  ],
  providers: [PageService, SortService, FilterService, ResizeService]
})
export class InterviewsListComponent implements OnInit {
  interviews?: InterviewDto[];
  applications?: ApplicationDto[];
  filteredInterviews?: InterviewDto[];
  public pageSettings?: PageSettingsModel;
  selectedPositionId: string | null = null;
  selectedStatus: string | null = null;
  statuses = Object.keys(InterviewStatus);
  positions: PositionDto[];
  public fields: Object = { text: 'jobTitle', value: 'id' };
  loading: boolean = true;

  ngOnInit(): void {
    if (this.authService.isHR()) {
      this.applicationsService.getAllApplications$().subscribe((applications) => {
        this.applications = applications;
        this.interviews = this.applications.flatMap(application =>
          application.interviews.map(interview => ({
            ...interview,
            application
          }))
        );
        if (this.interviews) {
          this.filteredInterviews = this.interviews;
        }
        this.extractUniquePositions();
        this.loading = false;
      });
    }
  }


  extractUniquePositions(): void {
    const positionMap = new Map<number, PositionDto>();
    this.applications.forEach((application) => {
      positionMap.set(Number.parseInt(application.position.id), application.position);
    });
    this.positions = Array.from(positionMap.values());
  }

  constructor(
    private router: Router,
    public service: InterviewsService,
    public positionsService: PositionsService,
    public applicationsService: ApplicationsService,
    public authService: CareersAuthService,
  ) {
    this.pageSettings = { pageSize: 30 };
  }

  navigateToInterviewDetail(interview: InterviewDto) {

    this.router.navigate(['/interviews', 'details', interview.id]);

  }

  getTeamValue(enumKey: string) {
    return getTeamValue(enumKey);
  }

  getLocationValue(enumKey: string) {
    return getLocationValue(enumKey);
  }

  applyFilter(value: PositionDto | string | null, filterType: string): void {
    // Reset the filteredInterviews to the original interviews array
    let filtered = this.interviews.slice();

    // Update the current filter values
    switch (filterType) {
      case 'position':
        this.selectedPositionId = value as string;
        break;
      case 'status':
        this.selectedStatus = value as string;
        break;
      default:
        break;
    }

    // Apply position filter if selectedPosition is set
    if (this.selectedPositionId) {
      filtered = filtered.filter(interview =>
        interview.application.position.id === this.selectedPositionId
      );
    }

    // Apply status filter if selectedStatus is set
    if (this.selectedStatus) {
      filtered = filtered.filter(interview =>
        interview.status === this.selectedStatus
      );
    }

    // Update the filteredInterviews array to the filtered result
    this.filteredInterviews = filtered;
  }
}
