import { Component } from '@angular/core';
import { CareersAuthService } from '../../auth/careers-auth.service';
import { Observable } from 'rxjs';
import { UsersService } from '../services/users.service';
import { AsyncPipe, UpperCasePipe } from '@angular/common';
import { AvatarCardComponent } from '../shared/avatar-card.component';
import { MatMenuModule } from '@angular/material/menu';
import { InterviewsNavMenuComponent } from './interview-nav.component';
import { PositionsNavMenuComponent } from './positions-nav.component';
import { ApplicationsNavMenuComponent } from './application-nav.component';
import { GraphUser } from '../model/user.model';
import { environment } from '../../environments/environment';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    UpperCasePipe,
    AsyncPipe,
    AvatarCardComponent,
    MatMenuModule,
    InterviewsNavMenuComponent,
    PositionsNavMenuComponent,
    ApplicationsNavMenuComponent
  ]
})
export class HeaderComponent {
  public me$: Observable<GraphUser> | null = null;
  public environment = environment.env;
  public loggedIn = false;
  public selectedMenuItem: string;

  constructor(
    private careersAuthService: CareersAuthService,
    private usersService: UsersService,
    private router: Router
  ) {
    this.loggedIn = this.careersAuthService.loggedIn;
    if (!this.loggedIn) {
      const intervalId = setInterval(() => {
        this.loggedIn = this.careersAuthService.loggedIn;
        if (this.loggedIn) {
          clearInterval(intervalId);
        }
      }, 1000);
    }
  }

  public ngOnInit(): void {
    // this.loggedIn = this.orderFlowAuthService.loggedIn;
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.updateSelectedMenuItem(event.urlAfterRedirects);        
      }
    });

    if (this.careersAuthService.account) {
      this.me$ = this.usersService.me$();
    }
  }

  updateSelectedMenuItem(url: string) {
    if (url.includes('/positions')) {
      this.selectedMenuItem = 'positions';
    } else if (url.includes('/applications')) {
      this.selectedMenuItem = 'applications';
    } else if (url.includes('/interviews')) {
      this.selectedMenuItem = 'interviews';
    } else {
      this.selectedMenuItem = '';
    }
  }

  public logout(): void {
    this.careersAuthService.logout$().subscribe();
  }
}
