import { Component, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule } from '@angular/common';
import { NgIf } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationDto } from '../../model/application-model';
import { ApplicationsService } from '../../services/applications.service';
import { ListViewModule } from '@syncfusion/ej2-angular-lists';
import { ApplicationStatus } from '../../model/enums/application-status';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DialogComponent, DialogModule } from '@syncfusion/ej2-angular-popups';
import { RemovingEventArgs, SelectedEventArgs, TextBoxModule, UploaderComponent, UploaderModule } from '@syncfusion/ej2-angular-inputs';
import { RichTextEditorAllModule } from '@syncfusion/ej2-angular-richtexteditor';
import { InterviewDto } from '../../model/interview-model';
import { InterviewsService } from '../../services/interviews.service';
import { CareersAuthService } from '../../../auth/careers-auth.service';
import { map, Observable, switchMap } from 'rxjs';
import { InterviewerDto } from '../../model/interviewer';
import { UsersService } from '../../services/users.service';
import { DatePickerModule, DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { GraphUser } from '../../model/user.model';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { InterviewerFormConfig } from '../../model/form-configs/interviewer-form-config';
import { InterviewStatus } from '../../model/enums/interview-status';
import { getApplicationStatusValue, getLocationValue, getTeamValue } from '../../shared/utils';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-interview-detail',
  templateUrl: './interview-detail.component.html',
  styleUrl: './interview-detail.component.scss',
  standalone: true,
  imports: [
    CommonModule,
    ListViewModule,
    FormsModule,
    DialogModule,
    TextBoxModule,
    RichTextEditorAllModule,
    DateTimePickerModule,
    ListViewModule,
    ListBoxModule,
    CheckBoxModule,
    UploaderModule
  ],
  providers: []
})
export class InterviewDetailComponent implements OnInit {
  application: ApplicationDto;
  interview: InterviewDto;
  previousInterviews: InterviewDto[];
  newInterviewDateTime: Date;
  notes: string = '';
  reassignForm: FormGroup;
  public staff: GraphUser[];
  public fields: Object = { text: 'displayName', value: 'email', groupBy: 'department', id: 'email' };
  minDateTime: Date;
  public selectedFile: File;

  @ViewChild('reassignDialog') reassignDialog: DialogComponent;
  @ViewChild('cancelDialog') cancelDialog: DialogComponent;
  @ViewChild('scheduleDialog') scheduleDialog: DialogComponent;
  @ViewChild('approveDialog') approveDialog: DialogComponent;
  @ViewChild('rejectDialog') rejectDialog: DialogComponent;
  @ViewChild('consideringDialog') consideringDialog: DialogComponent;

  @ViewChild('defaultupload')
  public uploadObj: UploaderComponent;

  ngOnInit() {
    this.minDateTime = new Date();
  }

  constructor(
    private route: ActivatedRoute,
    private applicationsService: ApplicationsService,
    private interviewsService: InterviewsService,
    private usersService: UsersService,
    private fb: FormBuilder,
    private authService: CareersAuthService,
  ) {


    this.route.params.subscribe(routeParams => {
      const interviewId = routeParams['id'];

      this.applicationsService.getApplicationByInterviewId$(interviewId).subscribe(application => {
        this.application = application;
        this.interviewsService.getInterview$(interviewId).subscribe(i => {
          this.interview = i
          this.previousInterviews = this.getPreviousInterviewsPostFormDocs();
        });
      });
    });


    this.usersService.getInterviewers$().subscribe((data) => {
      this.staff = data;
    });

    this.reassignForm = this.fb.group({
      interviewers: this.fb.array<FormGroup<InterviewerFormConfig>>([])
    });

  }

  isLastRound(): boolean {
    return this.interview.round.roundNumber === this.application.position.interviewProcess.rounds.length;
  }

  isInterviewScheduled() {
    const value = ApplicationStatus[this.application.status as keyof typeof ApplicationStatus];

    return this.interview.status == InterviewStatus.Scheduled ||
      this.interview.status == InterviewStatus.Completed ||
      this.interview.status == InterviewStatus.Considering ||
      value == ApplicationStatus.PotentialOffer;
  }

  isInterviewCanceled() {
    return this.interview.status == InterviewStatus.Canceled
  }

  toggleSelection(event: any) {
    const selectedEmail = event.data.email;
    const selectedUserDisplayName = event.data.name.display;
    const interviewersControl = this.reassignForm.get('interviewers') as FormArray;

    const index = interviewersControl.controls.findIndex(
      (ctrl) => ctrl.get('email').value === selectedEmail
    );

    if (index === -1) {
      const interviewerGroup = this.fb.group<InterviewerFormConfig>({
        email: new FormControl(selectedEmail),
        canViewSalary: new FormControl(false),
        displayName: new FormControl(selectedUserDisplayName)
      });
      interviewersControl.push(interviewerGroup);
    } else {
      interviewersControl.removeAt(index);
    }
  }

  toggleCheckbox(event: MouseEvent, email: string) {
    // Prevents the checkbox click from propagating to the span click event
    event.stopPropagation();
    const interviewersArray = this.reassignForm.get('interviewers') as FormArray;
    const interviewer = interviewersArray.controls.find(control => control.get('email').value === email);
    if (interviewer) {
      interviewer.get('canViewSalary').setValue(event.target['checked']);
    }
  }

  removeSelection(event: MouseEvent, email: string) {
    const interviewersArray = this.reassignForm.get('interviewers') as FormArray;
    const index = interviewersArray.controls.findIndex(control => control.get('email').value === email);
    if (index > -1) {
      interviewersArray.removeAt(index);
    }
  }

  getPreviousInterviewsPostFormDocs(): InterviewDto[] {
    return this.application.interviews.filter(i => i.id != this.interview.id &&
      i.round.roundNumber < this.interview.round.roundNumber);
  }

  getCurrentUserInterviewerInfo(): InterviewerDto {
    return this.interview.round.interviewers.find(interviewer => interviewer.email === this.authService.account.username)
  }

  openDialog(dialog: DialogComponent) {
    dialog.show();
  }

  closeDialog(dialog: DialogComponent) {
    dialog.hide();
  }

  confirmAction(dialog: DialogComponent, action: string) {
    if (action === 'schedule') {
      this.scheduleInterview();
    } else if (action === 'reassign') {
      this.reassignInterview();
    }
    else if (action === 'cancel') {
      this.cancelInterview();
    }
    else if (action === 'approve') {
      this.approveForNextInterview();
    }
    else if (action === 'reject') {
      this.rejectInterview();
    }
    else if (action === 'considering') {
      this.consideringInterview();
    }

    this.closeDialog(dialog);
  }

  rejectInterview() {
    const formData = new FormData();

    const interviewer = this.getCurrentUserInterviewerInfo();

    formData.append("id", this.application.id.toString());
    if (this.selectedFile) {
      formData.append("postInterviewFile", this.selectedFile, this.selectedFile.name);
    }
    formData.append("interviewId", this.interview.id.toString());
    formData.append("notes", this.notes);

    formData.append("interviewerDeserialized", JSON.stringify(interviewer));

    this.applicationsService.rejectAfterInterview$(formData).subscribe((application) => {
      this.application = application;
      this.interview = this.application.interviews.find(i => i.id === this.interview.id);
      this.notes = '';
    });
  }


  approveForNextInterview() {
    const formData = new FormData();
    const interviewer = this.getCurrentUserInterviewerInfo();


    formData.append("id", this.application.id.toString());
    if (this.selectedFile) {
      formData.append("postInterviewFile", this.selectedFile, this.selectedFile.name);
    }
    formData.append("interviewId", this.interview.id.toString());
    formData.append("interviewerDeserealized", JSON.stringify(interviewer));
    formData.append("notes", this.notes);

    this.applicationsService.approveAfterInterview$(formData).subscribe((application) => {
      this.application = application;
      this.interview = this.application.interviews.find(i => i.id === this.interview.id);
      this.notes = '';
    });
  }

  cancelInterview() {
    this.interviewsService.cancelInterview$(this.interview.id).subscribe((i) => {
      this.interview = i;
      this.notes = '';
    })
  }

  consideringInterview() {
    const formData = new FormData();
    const interviewer = this.getCurrentUserInterviewerInfo();


    formData.append("id", this.application.id.toString());
    if (this.selectedFile) {
      formData.append("postInterviewFile", this.selectedFile, this.selectedFile.name);
    }
    formData.append("interviewId", this.interview.id.toString());
    formData.append("interviewerDeserealized", JSON.stringify(interviewer));
    formData.append("notes", this.notes);

    this.interviewsService.consideringInterview$(formData).subscribe((interview) => {
      this.interview = interview;
      this.notes = '';
    });
  }

  getTotalNumberOfRounds() {
    return this.application.position.interviewProcess.rounds.length;
  }

  canClickApproveButton() {
    return (this.interview.status == InterviewStatus.Scheduled || this.interview.status == InterviewStatus.Considering)
      && (this.application.status != ApplicationStatus.Rejected) &&
      !this.interview.approvedBy.includes(this.authService.account.username) &&
      !this.interview.rejectedBy.includes(this.authService.account.username) &&
      this.isInterviewer();
  }

  isInterviewer() {
    return this.interview.round.interviewers.some(i => i.email == this.authService.account.username)
  }

  getApplicationStatusValue(enumKey: string) {
    return getApplicationStatusValue(enumKey);
  }

  getLocationValue(enumKey: string) {
    return getLocationValue(enumKey);
  }

  getTeamValue(enumKey: string) {
    return getTeamValue(enumKey);
  }

  canClickRejectButton() {
    return this.application.status != ApplicationStatus.Rejected &&
      !this.interview.approvedBy.includes(this.authService.account.username) &&
      !this.interview.rejectedBy.includes(this.authService.account.username) &&
      this.isInterviewer()
  }

  canClickConsideringButton() {
    return this.application.status != ApplicationStatus.Rejected && this.interview.status != InterviewStatus.Considering &&
      !this.interview.approvedBy.includes(this.authService.account.username) &&
      !this.interview.rejectedBy.includes(this.authService.account.username) &&
      this.isInterviewer()
  }

  scheduleInterview() {
    if (this.newInterviewDateTime && this.newInterviewDateTime.getTime() > Date.now()) {
      const formattedDateTime = new Date(this.newInterviewDateTime).toISOString();

      this.interviewsService.scheduleInterview$(this.interview.id, formattedDateTime).subscribe((i) => {
        this.interview = i;
        this.notes = '';
      });
    }
  }

  setPostInterviewDoc(event: SelectedEventArgs) {
    this.selectedFile = <File>event.filesData[0].rawFile;
  }

  public onFileRemove(args: RemovingEventArgs): void {
    args.postRawFile = false;
  }

  reassignInterview() {
    var interviewers = this.reassignForm.get('interviewers').value as InterviewerDto[];

    this.interviewsService.reassignInterview$(this.interview.id, interviewers, this.notes).subscribe((i) => {
      this.interview = i;
      this.notes = '';
    })
  }
}
