<div class="round-wrapper">
  <div class="round-number">
    Round {{ roundNumber }}
    <div class="row">
      <div class="col">
        <div class="round-form" [formGroup]="roundForm">
          <div class="lists-container">
            <div class="list-container">
              <div class="dual-list-groupa">
                <h5>Staff</h5>
                <ejs-listview
                  class="staff-list"
                  [dataSource]="staff"
                  [fields]="fields"
                  (select)="toggleSelection($event)"
                >
                  <ng-template #template let-data>
                    <div class="staff-item">
                      <span class="staff-name">{{ data.name.display }}</span>
                    </div>
                  </ng-template>
                </ejs-listview>
              </div>
            </div>
            <div class="list-container">
              <h5>Selected Interviewers</h5>
              <ejs-listview
                #selectedInterviewers
                class="selected-interviewers-list"
                [dataSource]="roundForm.get('interviewers').value"
                [fields]="fields"
              >
                <ng-template #template let-data>
                  <div class="selected-interviewer-item">
                    <label class="custom-checkbox">
                      <input type="checkbox" (click)="toggleCheckbox($event, data.email)" [checked]="data.canViewSalary" />
                      <span class="checkmark"></span>
                    </label>
                    <span (click)="removeSelection($event, data.email)">{{ data.email }}</span>
                  </div>
                </ng-template>
              </ejs-listview>
            </div>
          </div>
          <div class="remove-btn-container">
            <button class="remove-btn" (click)="removeRound()">Remove Round</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
