import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { AnimationModel } from '@syncfusion/ej2-progressbar';
import {
  PdfViewerComponent,
  LinkAnnotationService,
  BookmarkViewService,
  MagnificationService,
  ThumbnailViewService,
  ToolbarService,
  NavigationService,
  TextSearchService,
  TextSelectionService,
  PrintService,
  AnnotationService,
  FormDesignerService,
  FormFieldsService,
  PageOrganizerService,
  PdfViewerModule
} from '@syncfusion/ej2-angular-pdfviewer';
import {
  UploaderComponent,
  RemovingEventArgs,
  SelectedEventArgs,
  UploaderModule
} from '@syncfusion/ej2-angular-inputs';
import { filter } from 'rxjs/operators';
import { Router } from '@angular/router';
import { PositionDto } from '../../../model/position-model';
import { DataSource } from '../../../model/enums/data-source';
import { environment } from '../../../../environments/environment';
import { PositionsService } from '../../../services/positions.service';
import { UsersService } from '../../../services/users.service';
import { ApplicationDto } from '../../../model/application-model';
import { CareersAuthService } from '../../../../auth/careers-auth.service';
import { ApplicationFormConfig } from '../../../model/form-configs/application-form-config';
import { ApplicationsService } from '../../../services/applications.service';
import { CommonModule } from '@angular/common';
import { BlobStorageService } from '../../../services/blob-storage.service';
import { ProgressButtonModule, SpinSettingsModel } from '@syncfusion/ej2-angular-splitbuttons';
import { AnswerFormConfig } from '../../../model/form-configs/answer-form-config';
import { QuestionDto } from '../../../model/question-model';
import { QuestionAnswerComponent } from './question/question-answer.component';
import { AnswerDto } from '../../../model/answer-model';

interface t {
  question: QuestionDto;
  answer: FormGroup
}

@Component({
  selector: 'app-upload-cv',
  templateUrl: './upload-cv.component.html',
  styleUrls: ['./upload-cv.component.scss'],
  standalone: true,
  providers: [
    LinkAnnotationService,
    BookmarkViewService,
    MagnificationService,
    ThumbnailViewService,
    ToolbarService,
    NavigationService,
    TextSearchService,
    TextSelectionService,
    PrintService,
    AnnotationService,
    FormDesignerService,
    FormFieldsService,
    PageOrganizerService
  ],
  imports: [
    CommonModule, ReactiveFormsModule, FormsModule, UploaderModule, PdfViewerModule, ProgressButtonModule, QuestionAnswerComponent
  ]
})
export class UploadCVComponent implements OnInit {
  public parsed$: Boolean;
  public positions: PositionDto[];
  public selectedPosition: PositionDto;
  public applicationForm: FormGroup;
  public selectedFile: File;
  public parsingLoading$: Boolean;
  public submitLoading: Boolean;
  public submitDone$: Boolean;
  public animation: AnimationModel = { enable: true, duration: 2000, delay: 0 };
  public value: number = 40;
  public initialLoading: Boolean = false;

  getBase64(file: Blob) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  }

  @ViewChild('pdfviewer')
  public pdfviewerControl: PdfViewerComponent;

  public document: string = 'https://cdn.syncfusion.com/content/pdf/pdf-succinctly.pdf';
  public resource: string = 'https://cdn.syncfusion.com/ej2/23.1.40/dist/ej2-pdfviewer-lib';
  public spinSettings: SpinSettingsModel = { position: 'Right', width: 20, template: '<div class="template"></div>' };

  @ViewChild('defaultupload')
  public uploadObj: UploaderComponent;

  constructor(
    private positionsService: PositionsService,
    private applicationsService: ApplicationsService,
    private usersService: UsersService,
    private blobStorageService: BlobStorageService,
    private fb: FormBuilder,
    private http: HttpClient,
    private router: Router
  ) {
    this.applicationForm = this.fb.group({
      candidate: this.fb.group({
        displayName: [null, Validators.required],
        mail: [null, [Validators.required, Validators.email]],
        mobilePhone: [null, Validators.required],
        linkedIn: [null],
        country: [null, Validators.required]
      }),
      positionId: [null, Validators.required],
      azureB2CUserEmail: [null, Validators.email],
      cvFile: [null, Validators.required],
      mostRecentJobTitle: [null, Validators.required],
      mostRecentCompany: [null, Validators.required],
      expectedSalary: [null, Validators.required],
      availability: [null, Validators.required],
      answers: this.fb.array([
        this.fb.group<AnswerFormConfig>({
          questionId: new FormControl(null),
          answer: new FormControl(null)
        })
      ]),
    });

  }



  ngOnInit(): void {
    this.initialLoading = true;
    this.loadOpenPositions();
  }

  setPositionDetails(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    const selectedPositionId = selectElement.value;

    this.applicationForm.controls['positionId'].setValue(selectedPositionId);

    this.positionsService.getPosition$(selectedPositionId).subscribe((p) => {
      this.selectedPosition = p;

      if (this.selectedPosition?.questions?.length > 0) {
        // initialize answers element for each question in position
        const answers = this.applicationForm.get('answers') as FormArray;
        answers.clear();
        this.selectedPosition?.questions?.forEach(() => {
          answers.push(this.fb.group<AnswerFormConfig>({
            answer: new FormControl(null),
            questionId: new FormControl(null),
          }));
        });

      }

    })
  }

  getAggregateInterface(i: number): t {
    var answersArray = (this.applicationForm?.get('answers') as FormArray).controls;
    var answer = answersArray.at(i) as FormGroup<AnswerFormConfig>;
    var question = this.selectedPosition.questions?.at(i) as QuestionDto;

    answer.controls.questionId.setValue(question.id);

    return {
      question: question,
      answer: answer
    };
  }

  get candidateDisplayName() {
    return this.applicationForm.get('candidate.displayName');
  }
  get candidateEmail() {
    return this.applicationForm.get('candidate.mail');
  }
  get candidateMobilePhone() {
    return this.applicationForm.get('candidate.mobilePhone');
  }
  get candidateCountry() {
    return this.applicationForm.get('candidate.country');
  }
  get positionId() {
    return this.applicationForm.get('positionId');
  }
  get cvFile() {
    return this.applicationForm.get('cvFile');
  }
  get mostRecentJobTitle() {
    return this.applicationForm.get('mostRecentJobTitle');
  }
  get mostRecentCompany() {
    return this.applicationForm.get('mostRecentCompany');
  }
  get expectedSalary() {
    return this.applicationForm.get('expectedSalary');
  }
  get availability() {
    return this.applicationForm.get('availability');
  }

  loadOpenPositions() {
    this.positionsService.getAllPositions$().subscribe((data) => {
      this.positions = data;
    });
  }

  async uploadCvToStorage() {
    this.blobStorageService.uploadFile(this.selectedFile).subscribe((url: string) => {
    });
  }

  public onFileRemove(args: RemovingEventArgs): void {
    args.postRawFile = false;
  }

  private toTitleCase(str: string) {
    return str.replace(/\w\S*/g, function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
  }

  async parseCvInApi(event: SelectedEventArgs) {
    this.parsingLoading$ = true;
    this.selectedFile = <File>event.filesData[0].rawFile;

    const fd = new FormData();
    fd.append('file', this.selectedFile, this.selectedFile.name);
    this.applicationsService.analyzeCV$(fd).subscribe((data) => {
      this.applicationForm.controls.cvFile.setValue(this.selectedFile);

      const candidateGroup = this.applicationForm.controls['candidate'] as FormGroup;
      candidateGroup.controls['displayName'].setValue(this.toTitleCase(data.displayName));
      candidateGroup.controls['mail'].setValue(data.mail, { emitEvent: false });
      candidateGroup.controls['mobilePhone'].setValue(data.mobilePhone);
      candidateGroup.controls['linkedIn'].setValue(data.linkedin);
      candidateGroup.controls['country'].setValue(data.country);

      this.parsed$ = true;
      this.parsingLoading$ = false;

      var viewer = (<any>document.getElementById('pdfViewer')).ej2_instances[0];
      this.getBase64(this.selectedFile).then((pdf) => {
        viewer.load(pdf, null);
      });
    });
  }

  async submitApplication() {
    const candidateEmail = this.applicationForm.value.candidate.mail;

    this.usersService.getUserByEmail$(candidateEmail).subscribe((fetchedUser) => {
      if (fetchedUser == null) {
        this.usersService.createUser$(this.applicationForm.value.candidate).subscribe((user) => {
          this.createApplication(user.mail);
        });
      } else {
        this.createApplication(fetchedUser.mail);
      }
    });
  }

  createApplication(userEmail: string) {
    this.applicationForm.controls.azureB2CUserEmail.setValue(userEmail);

    const formData = new FormData();
    Object.keys(this.applicationForm.value).forEach((key) => {
      if (key === 'cvFile') {
        formData.append('cvFile', this.applicationForm.value.cvFile, this.applicationForm.value.cvFile.name);
      } else if (key !== 'candidate') {
        formData.append(key, this.applicationForm.value[key]);
      }
    });

    if (this.selectedPosition?.questions.length > 0 && this.applicationForm.get('answers').value) {
      var answers = this.applicationForm.get('answers').value as AnswerDto[];
      formData.append("answersDeserialized", JSON.stringify(answers));
    }

    this.applicationsService.createApplication$(formData).subscribe((app) => {
      this.router.navigate(['/applications', 'details', app.id]);
      this.applicationForm.reset();
    });
  }

}
