<nav class="navbar fixed-top navbar-expand-lg navbar-light bg-white">
  @if (loggedIn) {
  <div class="container d-flex justify-content-between">
    <a class="navbar-brand" href="/">
      <img src="assets/cigp-logo.webp" width="40" height="40" alt="CIGP" />
      <span class="mx-3">Careers Portal</span>
      @if (environment !== 'production') {
      <span class="e-badge e-badge-success text-white">{{ environment | uppercase }}</span>
      }
    </a>
    <button
      class="navbar-toggler"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav d-flex align-items-center">
        <div [class.selected]="selectedMenuItem === 'positions'">
          <of-positions-nav-menu></of-positions-nav-menu>
        </div>
        
        <div [class.selected]="selectedMenuItem === 'applications'">
          <of-applications-nav-menu></of-applications-nav-menu>
        </div>

        <div [class.selected]="selectedMenuItem === 'interviews'">
          <of-interviews-nav-menu></of-interviews-nav-menu>
        </div>
      </ul>
      <div class="me-auto"></div>
      <ul class="navbar-nav d-flex align-items-center">
        <of-avatar-card [user]="me$ | async" [matMenuTriggerFor]="userMenu"></of-avatar-card>
        <mat-menu #userMenu="matMenu">
          <button mat-menu-item (click)="logout()"><i class="bi bi-box-arrow-right"></i> LOGOUT</button>
        </mat-menu>
      </ul>
    </div>
  </div>
  }
</nav>
