import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { NGXLogger } from 'ngx-logger';
import { MatDialog } from '@angular/material/dialog';
import { LocalStorageService } from 'ngx-localstorage';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';
import { showErrorDialog } from '../shared/utils';
import { environment } from '../../environments/environment';
import { InterviewDto } from '../model/interview-model';
import { InterviewerDto } from '../model/interviewer';


@Injectable({
  providedIn: 'root'
})
export class InterviewsService {
  private apiEndpoint = `${environment.apiEndpoint}/api/interviews`;
  private refreshTrigger = new BehaviorSubject<void>(void 0);

  constructor(
    private http: HttpClient,
    private logger: NGXLogger,
    private errorDialog: MatDialog,
  ) { }


  public getInterview$(id: string): Observable<InterviewDto> {

    return this.http.get<InterviewDto>(`${this.apiEndpoint}/${id}`).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public scheduleInterview$(id: string, date: string): Observable<InterviewDto> {

    return this.http.put<InterviewDto>(`${this.apiEndpoint}/schedule/${id}?startDate=${date}`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public cancelInterview$(id: string): Observable<InterviewDto> {

    return this.http.put<InterviewDto>(`${this.apiEndpoint}/cancel/${id}`, {}).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public consideringInterview$(update: FormData): Observable<InterviewDto> {
    var id = update.get("interviewId");
    return this.http.put<InterviewDto>(`${this.apiEndpoint}/considering/${id}`, update).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  public reassignInterview$(id: string, interviewers: InterviewerDto[], reassignmentComments: string) {
    return this.http.put<InterviewDto>(`${this.apiEndpoint}/reassign/${id}?reassignmentComments=${reassignmentComments}`, interviewers).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );

  }

  getAllInterviews$(): Observable<InterviewDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<InterviewDto[]>(this.apiEndpoint).pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  getUpcomingInterviews$(): Observable<InterviewDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<InterviewDto[]>(this.apiEndpoint + "/upcoming").pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  getInterviewsByPosition$(positionId: string): Observable<InterviewDto[]> {
    return this.refreshTrigger.pipe(
      switchMap(() => {
        return this.http.get<InterviewDto[]>(`${this.apiEndpoint}/position/${positionId}`).pipe(
          catchError((error: any) => {
            this.logger.error(error);
            showErrorDialog(this.errorDialog, error);
            return throwError(() => new Error(error));
          })
        );
      })
    );
  }

  uploadPostInterviewDoc(id: string, file: File) {
    return this.http.put<InterviewDto>(`${this.apiEndpoint}/upload-post-interview-doc/${id}`, file).pipe(
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }

  total$(): Observable<number> {
    return this.getAllInterviews$().pipe(
      map((interviews) => interviews.length),
      catchError((error: any) => {
        this.logger.error(error);
        showErrorDialog(this.errorDialog, error);
        return throwError(() => new Error(error));
      })
    );
  }
}
