<div class="question-wrapper" [formGroup]="questionForm">
  <div class="question-number">
    Question {{ questionNumber }}
    <ejs-richtexteditor
      id="iframeRTE"
      [toolbarSettings]="tools"
      [iframeSettings]="iframe"
      [height]="height"
      [formControlName]="'question'"
    >
    </ejs-richtexteditor>
    <div class="mandatory">
      <ejs-checkbox
        label="Mandatory"
        #bcheckbox
        [formControlName]="'isMandatory'"
      ></ejs-checkbox>
    </div>
    <div class="remove-btn-container">
      <button class="remove-btn" (click)="removeQuestion()">
        Remove Question
      </button>
    </div>
  </div>
</div>
