<div class="parent-container">
  <h5>
    This page shows the interviews that are in the "Scheduled" status and have a starting date after the current time.
    You can select them to view and manage its details.
  </h5>
  <div *ngFor="let application of applications" class="interview-card-container">
    <div
      class="card app-interview-card-container"
      (click)="navigateToInterviewDetail(application, interview)"
      *ngFor="let interview of application.interviews"
    >
      <div class="card-header bg-primary">
        <h5 class="header-title">Interview for {{ application.position.jobTitle }}</h5>
      </div>
      <div class="card-body">
        <div class="info">
          <div class="info-label">Candidate:</div>
          <div class="info-content">{{ application.candidate.displayName }} ({{ application.candidate.mail }})</div>
        </div>
        <div class="info">
          <div class="info-label">Expected Salary:</div>
          <div class="info-content">{{ application.expectedSalary }}</div>
        </div>

        <div class="info">
          <div class="info-label">CV:</div>
          <a [href]="application.cvUrl" target="_blank" (click)="$event.stopPropagation()" rel="noopener noreferrer">Open</a>
        </div>
        <div class="info">
          <div class="info-label">Start Date:</div>
          <div class="info-content">{{ interview.startDate | date : 'short' }}</div>
        </div>
        <div class="info">
          <div class="info-label">Round:</div>
          <div class="info-content">
            {{ interview.round.roundNumber }} out of {{ application.position.interviewProcess.rounds.length }}
          </div>
        </div>
        <div class="info" *ngIf="getCurrentUserInterviewerInfo(interview).canViewSalary">
          <div class="info-label">Salary:</div>
          <div class="info-content">
            {{ application.position.salaryMin }} to {{ application.position.salaryMax }}
            {{ application.position.salaryCurrency }}
          </div>
        </div>
        <div class="action-buttons">
          <!-- Action Buttons -->
        </div>
      </div>
    </div>
  </div>
</div>
