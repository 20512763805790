import { Component, OnInit, ViewChild } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { Location } from '../../model/enums/location';
import { RecruitingMode } from '../../model/enums/recruiting-mode';
import { Team } from '../../model/enums/team';
import { PositionDto } from '../../model/position-model';
import { FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
import { MatFormFieldModule } from '@angular/material/form-field';
import { Router } from '@angular/router';
import { JobType } from '../../model/enums/job-type';
import { Observable } from 'rxjs';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader, SortEvent } from '../../shared/sortable.directive';
import { PositionsService } from '../../services/positions.service';
import { PositionTableService } from './position-table-service';
import { Column, DetailRowService, FilterService, GridComponent, GridModule, GroupService, PageService, PageSettingsModel, QueryCellInfoEventArgs, ResizeService, SortService } from '@syncfusion/ej2-angular-grids';
import { BrowserModule } from '@angular/platform-browser';
import { style } from '@angular/animations';
import { getLocationValue, getTeamValue } from '../../shared/utils';
import { CareersAuthService } from '../../../auth/careers-auth.service';
import { ROLE_HR_HK, ROLE_HR_DUBAI, ROLE_HR_GENEVA } from '../../shared/constants';
import { SpinnerComponent } from '../../shared/spinner.component';

@Component({
  selector: 'list-position',
  templateUrl: './position-list.component.html',
  styleUrl: './position-list.component.scss',
  standalone: true,
  imports: [
    MatIconModule,
    MatListModule,
    MatDividerModule,
    CommonModule,
    MatFormFieldModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    DecimalPipe,
    AsyncPipe,
    NgbPaginationModule,
    GridModule,
    SpinnerComponent
  ],
  providers: [PageService, SortService, FilterService, ResizeService, DetailRowService]
})
export class PositionListComponent implements OnInit {
  positions?: PositionDto[];
  public pageSettings?: PageSettingsModel;
  @ViewChild('grid', { static: true }) grid: GridComponent;
  loading: boolean = true;

  ngOnInit(): void {
    if (this.authService.isHR()) {
      this.loadApplicationsBasedOnRole();
    } else {
      this.loadUserPositions();
    }
  }

  private loadApplicationsBasedOnRole(): void {
    const roleServiceMap = {
      [ROLE_HR_HK]: this.service.getAllPositions$.bind(this.service),
      [ROLE_HR_DUBAI]: this.service.getAllDubaiPositions$.bind(this.service),
      [ROLE_HR_GENEVA]: this.service.getAllGenevaPositions$.bind(this.service)
    };

    for (const role in roleServiceMap) {
      if (this.authService.hasRole(role)) {
        roleServiceMap[role]().subscribe((positions) => this.handlePositions(positions));
      }
    }

    if (this.positions) {
      this.positions.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime());
    }
    this.loading = false;
  }

  private loadUserPositions(): void {
    this.service.getCurrentUserPositions$().subscribe((positions) => {
      this.handlePositions(positions);
      this.loading = false;
    });
  }

  private handlePositions(positions: PositionDto[]): void {
    this.positions = this.positions ? this.positions.concat(positions) : positions;
  }

  constructor(private router: Router, public service: PositionsService, public authService: CareersAuthService,
  ) {
    this.pageSettings = { pageSize: 30 };
  }

  navigateToPositionDetail(position: PositionDto) {
    this.router.navigate(['/positions', 'details', position.id]);
  }

  onDetailDataBound(args: any): void {
    const data = args.data as PositionDto;

    this.service.getPositionStats$(data.id).subscribe(details => {
      data.positionStats = details;
    });
  }

  getLocationValue(enumKey: string) {
    return getLocationValue(enumKey);
  }

  getTeamValue(enumKey: string) {
    return getTeamValue(enumKey);
  }

}
