import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CanViewInterviewsComponent } from '../interview/can-view-interviews.component';
import { CanWriteInterviewsComponent } from '../interview/can-write-interviews.component';
import { CanViewPositionsComponent } from '../position/can-view-positions.component';
import { CanWritePositionsComponent } from '../position/can-write-positions.component';

@Component({
  selector: 'of-positions-nav-menu',
  standalone: true,
  imports: [RouterModule, CanViewPositionsComponent, CanWritePositionsComponent],
  template: `
    <of-can-view-positions>
      <li class="nav-item dropdown">
        <a class="nav-link dropdown-toggle" href="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Positions
        </a>
        <ul class="dropdown-menu">
          <li>
            <a class="dropdown-item" [routerLink]="['/positions/']"><i class="bi bi-list"></i> LIST</a>
          </li>
          <of-can-write-position>
            <li>
              <a class="dropdown-item" [routerLink]="['/positions', 'create']"><i class="bi bi-plus-lg"></i> NEW</a>
            </li>
          </of-can-write-position>
        </ul>
      </li>
    </of-can-view-positions>
  `
})
export class PositionsNavMenuComponent {}
