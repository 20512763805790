import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CheckBoxSelection, ListBoxModule } from '@syncfusion/ej2-angular-dropdowns';
import { ListViewModule, SelectEventArgs } from '@syncfusion/ej2-angular-lists';
import { ListBoxComponent } from '@syncfusion/ej2-angular-dropdowns';
import { RoundFormConfig } from '../../../model/form-configs/round-form-config';
import { UsersService } from '../../../services/users.service';
import { GraphUser } from '../../../model/user.model';
import { InterviewerFormConfig } from '../../../model/form-configs/interviewer-form-config';
import { CheckBoxModule } from '@syncfusion/ej2-angular-buttons';
import { InterviewerDto } from '../../../model/interviewer';
import { environment } from '../../../../environments/environment';

ListBoxComponent.Inject(CheckBoxSelection);

@Component({
    selector: 'app-round',
    templateUrl: './round.component.html',
    styleUrls: ['./round.component.css'],
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        ListViewModule,
        ListBoxModule,
        CheckBoxModule,
    ]
})
export class RoundComponent {
    @Input() roundNumber: number;
    @Input() roundForm: FormGroup;
    @Output() removeRoundClicked = new EventEmitter<void>();
    public staff: GraphUser[];
    public fields: Object = { text: 'displayName', value: 'email', groupBy: 'department', id: 'email' };

    constructor(private fb: FormBuilder, private usersService: UsersService) {
        this.roundForm = this.fb.group<RoundFormConfig>({
            interviewers: this.fb.array([this.fb.group<InterviewerFormConfig>({
                email: new FormControl(null),
                canViewSalary: new FormControl(null),
                displayName: new FormControl(null)
            })]),
            roundNumber: new FormControl(null)
        });
    }

    ngOnInit(): void {
        this.roundForm.get('roundNumber').setValue(this.roundNumber);
        this.usersService.getInterviewers$().subscribe((data) => {
            this.staff = data;            
        });
    }

    toggleSelection(event: any) {
        const selectedEmail = event.data.email;
        const selectedUserDisplayName = event.data.name.display;
        const interviewersControl = this.roundForm.get('interviewers') as FormArray;

        const index = interviewersControl.controls.findIndex(
            (ctrl) => ctrl.get('email').value === selectedEmail
        );

        if (index === -1) {
            const interviewerGroup = this.fb.group<InterviewerFormConfig>({
                email: new FormControl(selectedEmail),
                canViewSalary: new FormControl(false),
                displayName: new FormControl(selectedUserDisplayName)
            });
            interviewersControl.push(interviewerGroup);
        } else {
            interviewersControl.removeAt(index);
        }
    }

    toggleCheckbox(event: MouseEvent, email: string) {
        // Prevents the checkbox click from propagating to the span click event
        event.stopPropagation();
        const interviewersArray = this.roundForm.get('interviewers') as FormArray;
        const interviewer = interviewersArray.controls.find(control => control.get('email').value === email);
        if (interviewer) {
            interviewer.get('canViewSalary').setValue(event.target['checked']);
        }
    }

    removeSelection(event: MouseEvent, email: string) {
        const interviewersArray = this.roundForm.get('interviewers') as FormArray;
        const index = interviewersArray.controls.findIndex(control => control.get('email').value === email);
        if (index > -1) {
            interviewersArray.removeAt(index);
        }
    }

    removeRound() {
        this.removeRoundClicked.emit();
    }
}
