import { Component, OnInit } from '@angular/core';
import { AsyncPipe, CommonModule, DecimalPipe } from '@angular/common';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatDividerModule } from '@angular/material/divider';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { NavigationExtras, Router } from '@angular/router';
import { NgbHighlight, NgbPaginationModule } from '@ng-bootstrap/ng-bootstrap';
import { NgbdSortableHeader } from '../../shared/sortable.directive';

import {
  FilterService,
  GridModule,
  PageService,
  PageSettingsModel,
  SortService
} from '@syncfusion/ej2-angular-grids';
import { ApplicationDto } from '../../model/application-model';
import { DropDownListModule } from '@syncfusion/ej2-angular-dropdowns';
import { InterviewsService } from '../../services/interviews.service';
import { ApplicationsService } from '../../services/applications.service';
import { InterviewDto } from '../../model/interview-model';
import { map, Observable } from 'rxjs';
import { InterviewerDto } from '../../model/interviewer';
import { UsersService } from '../../services/users.service';
import { CareersAuthService } from '../../../auth/careers-auth.service';

@Component({
  selector: 'upcoming-interviews',
  templateUrl: './upcoming-interviews.component.html',
  styleUrls: ['./upcoming-interviews.component.css'],
  standalone: true,
  imports: [
    MatIconModule,
    MatListModule,
    MatDividerModule,
    CommonModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    DecimalPipe,
    AsyncPipe,
    NgbPaginationModule,
    GridModule,
    DropDownListModule
  ],
  providers: [PageService, SortService, FilterService]
})
export class UpcomingInterviewsComponent implements OnInit {
  applications: ApplicationDto[] = [];
  constructor(
    private router: Router,
    public service: InterviewsService,
    public applicationsService: ApplicationsService,
    private authService: CareersAuthService,
  ) { }

  ngOnInit(): void {
    this.applicationsService.getUserApplicationInterviews$().subscribe((applications) => {
      this.applications = applications;
    });
  }

  navigateToInterviewDetail(application: ApplicationDto, interview: InterviewDto) {
    const navigationExtras: NavigationExtras = {
      state: {
        application,
        interview
      }
    };
    this.router.navigate(['/interviews', 'details', interview.id], navigationExtras);
  }

  getCurrentUserInterviewerInfo(interview: InterviewDto): InterviewerDto {
    return interview.round.interviewers.find(interviewer => interviewer.email === this.authService.account.username)
  }


}
