<div *ngIf="loading; else content">
  <app-spinner></app-spinner>
</div>
<ng-template #content>
  <div class="container">
    <ejs-grid
      #grid
      [dataSource]="positions"
      [allowPaging]="true"
      [allowSorting]="true"
      [allowFiltering]="true"
      [allowResizing]="true"
      [pageSettings]="pageSettings"
      (detailDataBound)="onDetailDataBound($event)"
    >
      <ng-template #detailTemplate let-data>
        <div class="detail-container" *ngIf="data.positionStats">
          <div class="detail-row">
            <div class="detail-item">
              <span class="detail-title">CVs Received: </span>
              <span class="detail-value">{{ data.positionStats.cVsReceived }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-title">HR Pre Screening: </span>
              <span class="detail-value">{{ data.positionStats.hrPreScreening }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-title">Interviews: </span>
              <span class="detail-value">{{ data.positionStats.interviews }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-title">Offers: </span>
              <span class="detail-value">{{ data.positionStats.offers }}</span>
            </div>
            <div class="detail-item">
              <span class="detail-title">Rejected or Withdrawn: </span>
              <span class="detail-value">{{ data.positionStats.rejectedOrWithdrawn }}</span>
            </div>
          </div>
        </div>
      </ng-template>
      <e-columns>
        <e-column field="Id" headerText="No." textAlign="Center" width="45"></e-column>
        <e-column field="jobTitle" headerText="Title" width="120" textAlign="Center" cssClass="job-title-cell">
          <ng-template #template let-data>
            <a (click)="navigateToPositionDetail(data)">{{ data.jobTitle }}</a>
          </ng-template>
        </e-column>
        <e-column field="location" headerText="Location" textAlign="Center" format="C2" width="90">
          <ng-template #template let-data>
            {{ getLocationValue(data.location) }}
          </ng-template>
        </e-column>
        <e-column field="team" headerText="Team" textAlign="Center" format="C2" width="90">
          <ng-template #template let-data>
            {{ getTeamValue(data.team) }}
          </ng-template>
        </e-column>
        <e-column field="closed" headerText="Status" textAlign="Center" format="yMd" width="120">
          <ng-template #template let-data>
            {{ data.closed ? 'Closed' : 'Open' }}
          </ng-template>
        </e-column>
        <e-column field="startDate" headerText="Start Date" type="date" textAlign="Center" width="120">
          <ng-template #template let-data>
            {{ data.startDate === '0001-01-01T00:00:00+00:00' ? 'NA' : (data.startDate | date : 'dd-MM-yyyy') }}
          </ng-template>
        </e-column>
        <e-column field="deadline" headerText="Deadline" type="date" textAlign="Center" width="120">
          <ng-template #template let-data>
            {{ data.deadline === '0001-01-01T00:00:00+00:00' ? 'NA' : (data.deadline | date : 'dd-MM-yyyy') }}
          </ng-template>
        </e-column>
      </e-columns>
    </ejs-grid>
  </div>
</ng-template>
